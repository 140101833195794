import { useContext } from "react";
import { useIntl } from "react-intl";
import { AccountContext } from "../../contexts/context";

import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import { Translation } from "../common/Translation";
import classes from "./DashboardGraphs.module.scss";
import emptyGraphData from "../../public/empty_state_graph.png";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const CATEGORY_COLOR = {
  "conversion": "rgba(255, 197, 172, 1)",   // #FFC5AC
  "trafic": "rgba(212, 241, 175, 1)",       // #D4F1AF
  "reactivation": "rgba(255, 204, 238, 1)", // #FCE
  "events": "rgba(209, 211, 255, 1)",       // #D1D3FF
  "customs": "rgba(221, 218, 213, 1)",      // #DDDAD5
  "newsletter": "rgba(255, 231, 185, 1)"    // #FFE7B9
};

const CATEGORY_COLOR_HOVER = {
  "trafic" : "rgba(193, 235, 141, 1)",
  "conversion" : "rgba(255, 172, 137, 1)",
  "events" : "rgba(151, 155, 240, 1)",
  "newsletter" : "rgba(255, 212, 129, 1)",
  "reactivation" : "rgba(253, 181, 228, 1)",
  "customs" : "rgba(190, 186, 179, 1)"
};

export const DashboardGraphs = ({ data, loading, dataType }) => {
  const intl = useIntl();
  const { account } = useContext(AccountContext);

  const sortedData = data?.sort((a, b) =>  dataType === "ca" ? b.turnover - a.turnover : b.nb_order - a.nb_order);
  const labels = sortedData?.map(row => shorttenText(getCampaignName(row) || row.usecase));
  const rawData = dataType === "ca" ? sortedData?.map(row => row.turnover) : sortedData?.map(row => row.nb_order);
  const columnColors = sortedData?.map(row => CATEGORY_COLOR[row.category] || CATEGORY_COLOR["newsletter"]);
  const columnHoverColors = sortedData?.map(row => CATEGORY_COLOR_HOVER[row.category] || CATEGORY_COLOR_HOVER["newsletter"]);

  function shorttenText(text) {
    return text.length > 15 ? text.substring(0, 15) + "..." : text;
  }
  
  const graphData = {
    labels: labels,
    datasets: [
      {
        data: rawData,
        backgroundColor: columnColors,
        borderRadius: 10,
        barThickness: -1.7 * rawData?.length + 60,
        hoverBackgroundColor: columnHoverColors,
      },
    ],
  };
    
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    width: "100%",
    scales: {
      x: {
        grid: {
          display: false
        },
        ticks: {
          font: {
            family: "Poppins", 
          }
        }
      },
      y: {
        border: {
          dash: [5, 5]
        }
      }
    },
    plugins: {
      legend: {
        display: false
      },
      title: {
        display: false
      },
      tooltip: {
        xAlign: "center",
        yAlign: "bottom",
        displayColors: false,
        bodyFont: {
          family: "Poppins"
        },
        titleFont: {
          family: "Poppins"
        },
        callbacks: {
          title: function(tooltipItems) {
            const index = tooltipItems[0].dataIndex;
            return sortedData[index] ? getCampaignName(sortedData[index]) || sortedData[index].usecase : "";
          },
          label: function(tooltipItem) {
            return `${tooltipItem.raw} ${dataType === "ca" ? "€" : ""}`;
          }
        },
        bodyAlign: "center",
      }
    }
  }; 

  function getCampaignName(row) {
    if (row.usecase === "newsletter") {
      return account.mjmlTemplates.find(template => template.id === row.newsletter_id)?.name || intl.messages["dashboard.graph.newsletter"];
    }

    return `${intl.messages[`usecase.${row.usecase}`]}${Number.isInteger(+row.usecase.substr(-1)) ? ` ${row.usecase.substr(-1)}` : ""}`;
  };
    
  return (
    <>
      { data?.length ? 
        <div style={{ width: "100%", height: "400px" }}>
          { loading ? null : <Bar data={graphData} options={options} /> }
        </div> :  
        <div className={classes.noDataWrapper}>
          <Translation id="dashboard.noData" />
          <img className={classes.noDataImage} src={emptyGraphData} alt="No data" />
        </div>
      }
    </>
  );
};