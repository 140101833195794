const LABEL = "subtitle";
const CATEGORY = "text:subtitle";

export const subtitle = (variables, subtitleAlign = "center") => ({
  label: LABEL,
  category: CATEGORY,
  block: (overrides) => {

    const { titleFontProps={}, subtitleFontProps={}, backgroundColor, title, subtitle, productIds = [] } = {
      ...variables,
      ...(overrides ? overrides : {}),
    };

    const mjml = `
      <mj-wrapper data-gjs="kilibaBlock" background-color="${backgroundColor}" padding-top="0" padding-bottom="0" full-width="full-width" data-text-product-ids="${productIds.join(",")}">
        <mj-section>
          <mj-column>
            <mj-text text-decoration="${titleFontProps.textDecoration}" font-style="${titleFontProps.fontStyle}" font-weight="${titleFontProps.fontWeight}" font-family="${titleFontProps.fontFamily}" color="${titleFontProps.color}" line-height="1.3" font-weight="600" font-size="18px" align="${subtitleAlign}" data-text-id="title">
              {{title}}
            </mj-text>
            <mj-text text-decoration="${subtitleFontProps.textDecoration}" font-style="${subtitleFontProps.fontStyle}" font-weight="${subtitleFontProps.fontWeight}" font-family="${subtitleFontProps.fontFamily}" color="${subtitleFontProps.color}" line-height="1.3" font-size="16px" align="${subtitleAlign}" data-text-id="subtitle">
              {{subtitle}}
            </mj-text>
          </mj-column>
        </mj-section>
      </mj-wrapper>
    `;

    const wordings = {
      title,
      subtitle,
    };

    return {
      id: LABEL,
      label: LABEL,
      category: CATEGORY,
      activate: true,
      content: mjml,
      attributes: {
        isDefaultBlock: true,
        wordings,
        editableProperties: [
          { key: "texts", type: "generatedTexts", label: "", group: "newsletter.rightMenu.propertyGroup.generatedTexts" },
          { key: "backgroundColor", type: "color", label: "newsletter.rightMenu.property.backgroundColor", group: "newsletter.rightMenu.propertyGroup.block" },
        ]
      }
    };
  }
});