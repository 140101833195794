const LABEL = "paragraph6";
const CATEGORY = "text:paragraph";

export const paragraph6 = (variables) => ({
  label: LABEL,
  category: CATEGORY,
  block: (overrides) => {

    const { textFontProps = {}, imageUrl, backgroundColor, text, imageHref = "", imageAlt = "", productIds = [] } = {
      ...variables,
      ...(overrides ? overrides : {}),
    };

    const mjml = `
      <mj-wrapper data-gjs="kilibaBlock" background-color="${backgroundColor}" padding-top="0" padding-bottom="0" full-width="full-width" data-text-product-ids="${productIds.join(",")}">
        <mj-section padding-right="25px" padding-left="25px">
          <mj-column width="75%" vertical-align="middle">
            <mj-text text-decoration="${textFontProps.textDecoration}" font-style="${textFontProps.fontStyle}" font-weight="${textFontProps.fontWeight}" color="${textFontProps.color}" font-family="${textFontProps.fontFamily}" padding-left="10px" padding-right="10px" line-height="1.3" font-size="14px" align="left" data-text-id="text">
              {{content}}
            </mj-text>
          </mj-column>
          <mj-column width="25%" vertical-align="middle">
            <mj-image href="${imageHref}" alt="${imageAlt}" width="100px" padding-left="10px" padding-right="10px" align="center" src="${imageUrl}"></mj-image> 
          </mj-column>
        </mj-section>
      </mj-wrapper>
    `;

    const wordings = {
      content: text,
    };

    return {
      id: LABEL,
      label: LABEL,
      category: CATEGORY,
      activate: true,
      content: mjml,
      attributes: {
        wordings,
        editableProperties: [
          { key: "texts", type: "generatedTexts", label: "", group: "newsletter.rightMenu.propertyGroup.generatedTexts" },
          { key: "imageUrl", type: "image", label: "newsletter.rightMenu.property.selectedImage", group: "newsletter.rightMenu.propertyGroup.image" },
          { key: "imageHref", type: "link", label: "newsletter.rightMenu.property.imageLink", group: "newsletter.rightMenu.propertyGroup.image" },
          { key: "imageAlt", type: "string", label: "newsletter.rightMenu.property.alt", group: "newsletter.rightMenu.propertyGroup.image" },
          { key: "backgroundColor", type: "color", label: "newsletter.rightMenu.property.backgroundColor", group: "newsletter.rightMenu.propertyGroup.block" },
        ]
      }
    };
  }
});