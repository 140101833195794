const LABEL = "paragraph1";
const CATEGORY = "text:paragraph";

export const paragraph1 = (variables) => ({
  label: LABEL,
  category: CATEGORY,
  block: (overrides) => {

    const {  titleFontProps={}, textFontProps={}, text, backgroundColor, title, productIds = [] } = {
      ...variables,
      ...(overrides ? overrides : {}),
    };

    const mjml = `
      <mj-wrapper data-gjs="kilibaBlock" background-color="${backgroundColor}" padding-top="0" padding-bottom="0" full-width="full-width" data-text-product-ids="${productIds.join(",")}">
        <mj-section>
          <mj-column>
            <mj-text text-decoration="${titleFontProps.textDecoration}" font-style="${titleFontProps.fontStyle}" font-weight="${titleFontProps.fontWeight}" color="${titleFontProps.color}" font-family="${titleFontProps.fontFamily}" font-size="18px" align="left" data-text-id="title">
              {{title}}
            </mj-text>
            <mj-text text-decoration="${textFontProps.textDecoration}" font-style="${textFontProps.fontStyle}" font-weight="${textFontProps.fontWeight}" color="${textFontProps.color}" font-family="${textFontProps.fontFamily}" line-height="1.3" font-size="14px" align="left" data-text-id="text">
              {{content}}
            </mj-text>
          </mj-column>
        </mj-section>
      </mj-wrapper>
    `;

    const wordings = {
      title,
      content: text,
    };

    return {
      id: LABEL,
      label: LABEL,
      category: CATEGORY,
      activate: true,
      content: mjml,
      attributes: {        
        wordings,
        editableProperties: [
          { key: "texts", type: "generatedTexts", label: "", group: "newsletter.rightMenu.propertyGroup.generatedTexts" },
          { key: "backgroundColor", type: "color", label: "newsletter.rightMenu.property.backgroundColor", group: "newsletter.rightMenu.propertyGroup.block" },
        ]
      }
    };
  }
});