import React, { useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import { TextField } from "./TextField";
import { palette } from "../../../styles/palette.js";

import PropTypes from "prop-types";
import { KiPopper } from "../KiPopper/KiPopper";

const styles = theme => ({
  root: {
    position: "relative",

    "& input": {
      cursor: "pointer !important",
      boxShadow: "0px 1px 7px 0px rgba(120, 120, 120, 0.25)",
    }
  },

  arrow: {
    top: 14,
    right: 20,
    position: "absolute",
    color: palette.neutralDarkGrey,
    fontSize: 13,
    pointerEvents: "none",
  },

  dropdown: {
    background: props => (props.variant === "table" || props.variant === "secondary" ) ? palette.primaryWhite : palette.neutralInputs,
    borderRadius: 10,    
    width: "fit-content",
    marginTop: 8,
    marginBottom: 8,
    overflowY: "auto",
    border: `1px solid ${palette.neutralLightGrey}`,
    maxHeight: props => props.dataCy === "businessSector" ? "150px" : null,


    "& > *:not(:last-child)": {
      borderBottom: props => props.children ? null : `1px solid ${palette.neutralLightGrey}`,
    },
  },

  dropdownElement: {
    height: props => props.variant === "table" ? 40 : 45,
    lineHeight: props => props.variant === "table" ? "40px" : "45px",
    padding: "0 20px",
    fontFamily: "Poppins",
    color: palette.primaryBlack,
    fontSize: "14px",

    "&:hover": {
      background: palette.neutralBCGGreyHover,
      cursor: "pointer"
    }
  },
  selected: {
    background: props => props.variant === "table" ? palette.neutralInputs : palette.primaryWhite,
  },
  arrowOpen: {
    color: palette.primaryBlack,
  }
});

const useStyles = makeStyles(styles);

export const KiDropdown = (props) => {
  const {
    noShadow,
    placeholder = "",
    value,
    label,
    options = [],
    onChange,
    dataCy,
    variant,
  } = props;

  const classes = useStyles(props);
  const [isOpen, setIsOpen] = useState(false);
  const anchor = useRef();

  const classNames = [
    classes.root,
  ];

  if (variant) {
    const capitalizedVariant = variant[0].toUpperCase() + variant.slice(1, Infinity);
    classNames.push(classes[`variant${capitalizedVariant}`]);
  }

  const textFieldStyles = {
    backgroundColor: variant === "table" ? palette.primaryWhite : variant === "secondary" ? palette.black : palette.neutralInputs,
    height: variant === "table" ? 40 : 45,
  };

  const textFieldStyleShadowed = {
    backgroundColor: variant === "table" ? palette.primaryWhite : variant === "secondary" ? palette.black : palette.neutralInputs,
    height: variant === "table" ? 40 : 45,
    boxShadow: "0px 1px 7px 0px rgba(120, 120, 120, 0.25)",
  };

  function renderOptions() {
    return options.map(elem => (
      <div
        key={elem.value}
        className={`${classes.dropdownElement} ${value === elem.value ? classes.selected : ""}`}
        onClick={() => {
          onChange(elem.value);
          setIsOpen(false);
        }}
      >
        {elem.label}
      </div>
    ));
  }

  return (
    <div data-cy={dataCy} className={classNames.join(" ")}>
      <TextField
        placeholder={placeholder}
        value={label || options.find(option => option.value === value)?.label}
        readOnly
        onClick={() => setIsOpen(!isOpen)}
        onChange={() => { }}
        type="dropdown"
        style={ noShadow ? textFieldStyles : textFieldStyleShadowed }
        anchor={anchor}
        variant={variant}
        dropdownIsOpen={isOpen}
      />

      <KiPopper
        anchor={anchor}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        placement={props.placement}
        flipIsEnabled={props.flipIsEnabled}
      >
        <div className={classes.dropdown} style={{ maxHeight:"250px" ,textAlign: props.variant === "centered" ? "center" : null ,minWidth: anchor?.current ? Number(getComputedStyle(anchor.current).width.replace("px", "")) : null }}>
          {
            props.children
              ? props.children
              : renderOptions()
          }
        </div>
      </KiPopper>
    </div>
  );
};

KiDropdown.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.string, label: PropTypes.string })),
  onChange: PropTypes.func,
  dataCy: PropTypes.string,
  variant: PropTypes.oneOf(["", "table"]),
  flipIsEnabled: PropTypes.bool,
};

KiDropdown.defaultProps = {
  placeholder: "",
  options: [],
  variant: "",
  flipIsEnabled: true,
};
