import { useEffect, useState, useMemo, useCallback } from "react";
import { useIntl } from "react-intl";
import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";

import { KiTable } from "../common/KiTable/KiTable.js";
import { KiTextField } from "../common/Input/KiTextField.js";
import { Dialog } from "../common/Dialog";
import { Translation } from "../common/Translation";

import { useAuth } from "../../hooks/useAuth";

import classes from "./PopupProductsCategories.module.scss";
import classNames from "classnames";
import { SelectPicker } from "../common/SelectPicker.js";
import { CustomButton } from "../common/Button/CustomButton.js";
import { Breadcrumb } from "./Breadcrumb.jsx";

export const PopupProductsCategories = ({ isOpen, close, resetData }) => {

  const auth = useAuth();
  const intl = useIntl();

  const [initialRows, setInitialRows] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [statuses, setStatuses] = useState([
    { isActive: true, key: "visible" },
    { isActive: true, key: "blacklisted" }
  ]);
  const [search, setSearch] = useState("");
  const [debouncedSearch, setDebouncedSearch] = useState("");
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [onlyBlacklistedOnRootMode, setOnlyBlacklistedOnRootMode] = useState(false);
  const [pagination, setPagination] = useState({
    rowsPerPage: 10,
    pageKey: 0,
    page: 0
  });
  const [isBlacklisted, setIsBlacklisted] = useState(false);
  const [isWhitelisted, setIsWhitelisted] = useState(false);
  const [isBlacklistConfirmationPopupOpen, setIsBlacklistConfirmationPopupOpen] = useState(false);
  const [clickedRow, setClickedRow] = useState(null);
  const [breadcrumbNeedsReset, setBreadcrumbNeedsReset] = useState(false);
  const [preventDataReload, setPreventDataReload] = useState(false);
  const [currentPath, setCurrentPath] = useState([]);
  const [needsResetInitialRows, setNeedsResetInitialRows] = useState(false);

  const columns = [
    { key: "id", name: "id"},
    { key: "name", name: intl.messages["categories.table.head.name"] },
    { key: "status", name: intl.messages["categories.table.head.status"] },
    { key: "subCategories", name: intl.messages["categories.table.head.subCategories"] },
    { key: "seeAllProductsAction", name: ""},
    { key: "goToChildCategoryAction", name: ""},
  ];
  const searchSubject = useMemo(() => new Subject(), []);

  useEffect(() => {
    const subscription = searchSubject.pipe(debounceTime(500)).subscribe((value) => {
      setDebouncedSearch(value);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [searchSubject]);

  const loadCategories = useCallback(async (parentCategoryId = null) => {
    setTableData([]);
    setLoading(true);

    let url = "/api/data/productCategories?limit=10000&page=0&";

    if(!debouncedSearch && (parentCategoryId || (clickedRow && clickedRow.id !== -1))) {
      url += `parentCategoryId=${parentCategoryId || clickedRow?.id}&`;
    }

    if (debouncedSearch && !parentCategoryId) {
      url += `searchText=${debouncedSearch}&`;
    }

    const activeStatus = getActiveFilters(statuses);
    if (activeStatus.length !== statuses.length) {
      activeStatus.forEach((activeFilter) => {
        url += `statuses=${activeFilter.key}&`;
      });
    }

    return auth.fetch(url).then(data => {
      let formattedData = data.categories.map(category =>  {
        const statusIcon = (
          <div className={classNames(classes.statusIcon, category.blacklist_date ? classes.redIcon : classes.greenIcon)}></div>
        );
        return {
          ...category,
          isSelected: false,
          id: category.id_category,
          values: {
            id: { displayedValue: category.id_category },
            name: {
              displayedValue:
              shorttenText(category.name[intl.locale] ||
                category.name.fr ||
                category.name.en ||
                category.name.es ||
                category.name ||
                "-", 25)
            },
            status: {
              displayedValue: category.blacklist_date
                ? intl.messages["categories.filters.status.blacklisted"]
                : intl.messages["categories.filters.status.visible"],
              startAdornment: statusIcon
            },
            subCategories: {
              htmlContent: (
                <div
                  className={
                    category.blacklisted_sub_categories_total > 0
                      ? classes.redCenteredText
                      : null
                  }
                >
                  {category.blacklisted_sub_categories_total}/{category.sub_categories_total}
                </div>
              )
            },
            seeAllProductsAction: {
              htmlContent:
                category.total_products > 0 ? (
                  <span
                    className={classes.seeAllProductLink}
                    onClick={(event) => {
                      event.stopPropagation();
                      close(category.id_category);
                    }}
                  >
                    {intl.messages["category.popup.see_products"]}
                  </span>
                ) : (
                  ""
                )
            },
            goToChildCategoryAction: {
              displayedValue:
                category.sub_categories_total > 0 ? (
                  <span className={classes.cursorPointer}>&gt;</span>
                ) : (
                  ""
                )
            }
          }
        };
      });

      if (onlyBlacklistedOnRootMode) {
        formattedData = formattedData.filter((row) => row.blacklist_date);
      }

      setTableData(formattedData);

      if (!initialRows.length) {
        setInitialRows(formattedData);
      }

      if (needsResetInitialRows) {
        const initialDataClone = [...initialRows];
        for(const row of formattedData) {
          const index = initialDataClone.findIndex((initialRow) => initialRow.id === row.id);
          if(index !== -1) {
            initialDataClone[index] = row;
          }
        }
        setInitialRows(initialDataClone);
      }

      setLoading(false);
      setNeedsResetInitialRows(false);

      return formattedData;
    });
  }, [debouncedSearch, clickedRow, statuses, auth, onlyBlacklistedOnRootMode, initialRows, needsResetInitialRows, intl.locale, intl.messages, close]);

  useEffect(() => {
    if(!preventDataReload || needsResetInitialRows) {
      loadCategories();
    }
  }, [auth, intl.locale, intl.messages, debouncedSearch, statuses, onlyBlacklistedOnRootMode, loadCategories, preventDataReload, needsResetInitialRows]);

  const shorttenText = (text, maxLength) => {
    return text.length > maxLength ? text.substring(0, maxLength) + "..." : text;
  };

  function switchStatuses(name, value) {
    setPreventDataReload(false);
    const newStatuses = JSON.parse(JSON.stringify(statuses));

    newStatuses.forEach((newStatus) => {
      newStatus.isActive = value.includes(intl.messages[`categories.filters.status.${newStatus.key}`]);
    });

    const activeStatuses = getActiveFilters(newStatuses);
    if (activeStatuses.length === 1 && activeStatuses[0].key === "blacklisted") {
      setOnlyBlacklistedOnRootMode(true);
    } else {
      setOnlyBlacklistedOnRootMode(false);
    }
    setStatuses(newStatuses);
    setPagination({ ...pagination, page: 0, pageKey: 0 });
  };

  const handleInputChange = (event) => {
    setPreventDataReload(false);
    setPagination({ ...pagination, page: 0, pageKey: 0 });
    setSearch(event.target.value);
    setBreadcrumbNeedsReset(true);
    searchSubject.next(event.target.value);
  };

  const onSelectCategory = (id) => {
    const updatedRows = tableData.map(row => row.id === id ? { ...row, isSelected: !row.isSelected} : row);
    setSelectedCategories(updatedRows.filter(row => row.isSelected).map(row => row.id));
    setTableData(updatedRows);
  };

  const onSelectAll = (ids) => {
    const updatedRows = tableData.map(row => ({ ...row, isSelected: ids.length ? true : false}));
    setSelectedCategories(updatedRows.filter(row => row.isSelected).map(row => row.id));
    setTableData(updatedRows);
  };

  const unselectAll = () => {
    setTableData(tableData.map(row => ({ ...row, isSelected: false })));
    setSelectedCategories([]);
  };

  const onClickRow = (clickedRow) => {
    setPreventDataReload(true);
    setClickedRow(clickedRow);
    setDebouncedSearch(null);
    setSearch("");
    setPagination({ ...pagination, page: 0, pageKey: 0 });
    
    //reset des status
    const newStatuses = JSON.parse(JSON.stringify(statuses));
    newStatuses.forEach((newStatus) => {
      newStatus.isActive = true;
    });
    setStatuses(newStatuses);

    if (clickedRow.id === -1) {
      setBreadcrumbNeedsReset(true);
      setSelectedCategories([]);
      setPagination({ ...pagination, page: 0, pageKey: 0 });
      
      setTableData(initialRows);
  
      return;
    }
  
    if (clickedRow.sub_categories_total > 0) {
      setSelectedCategories([]);
      const updatedRows = initialRows.filter((row) => row.id_parent === clickedRow.id);
      if (updatedRows.length !== +clickedRow.sub_categories_total) {
        loadCategories(clickedRow.id);
      }
      setTableData(updatedRows);
    }
  };

  const blacklistCategories = () => {
    auth.fetch("/data/blacklistProductCategories", 
      { 
        method: "POST", 
        body: JSON.stringify({
          categories: selectedCategories
        })
      })
      .then(() => {
        setNeedsResetInitialRows(true);
        setSelectedCategories([]);
        resetData();
        setIsBlacklistConfirmationPopupOpen(false);
      });
  };

  const whitelistCategories = () => {
    auth.fetch("/data/whitelistProductCategories", 
      { 
        method: "POST", 
        body: JSON.stringify({
          categories: selectedCategories
        })
      })
      .then(() => {
        setNeedsResetInitialRows(true);
        setSelectedCategories([]);
        resetData();
      });
  };

  function getActiveFilters(filterObject) {
    if (!filterObject) { return; }
    return filterObject
      .filter(x => x.isActive);
  };

  function savePagination(newPagination) {
    if (JSON.stringify(newPagination) !== JSON.stringify(pagination)) {
      setPagination(newPagination);
    }
  }

  useEffect(() => {
    const checkBlacklist = () => {
      const result = selectedCategories.some(categoryId => 
        initialRows.find(row => row.id === categoryId && row.blacklist_date)
      );
      setIsBlacklisted(result);
    };

    checkBlacklist();
  }, [selectedCategories, initialRows]); 

  useEffect(() => {
    const checkWhitelist = () => {
      const result = selectedCategories.some(categoryId => 
        initialRows.find(row => row.id === categoryId && row.blacklist_date === null)
      );
      setIsWhitelisted(result);
    };

    checkWhitelist();
  }, [selectedCategories, initialRows]); 

  return (
    <>
      <Dialog
        PaperProps={{ style: { borderRadius: 40, overflowY: "scroll", width: "85%", marginLeft: "15%" }}}
        isOpen={isOpen}
        close={() => { unselectAll(); close(null); }}
      >
        <div>
          <div className={classes.title}><Translation id="popupProductsCategories.title" /></div>
          <div className={classNames(classes.subTitle,classes.MT35)}><Translation id="popupProductsCategories.subTitle" /> : 
            { 
              !loading && initialRows?.length ? 
                ` ${initialRows.filter(row => row.blacklist_date !== null).length} / ${initialRows.length}` :
                " -"
            }
          </div>
          <Breadcrumb 
            initialRows={initialRows}
            rows={tableData} 
            debouncedSearch={debouncedSearch}
            loading={loading}
            onClickRow={onClickRow}
            lastRowClicked={clickedRow}
            needsReset={breadcrumbNeedsReset}
            setBreadcrumbNeedsReset={setBreadcrumbNeedsReset}
            currentPath={currentPath}
            setCurrentPath={setCurrentPath}
          >
          </Breadcrumb>
          <div className={classes.separator}></div>

          {/* -- CTAS -- */}
          <div className={classes.ctas}>
            <div className={classes.leftWrapper}>
              <SelectPicker
                selectList={statuses.map(status => intl.messages[`categories.filters.status.${status.key}`])}
                selected={statuses.filter(status => status.isActive).map(status => intl.messages[`categories.filters.status.${status.key}`] || status.key)}
                action={(name, value) => switchStatuses(name, value)}
                displayName={intl.messages["products.table.head.status"]}
              />
              <span className={classes.spacedCta15}>
                <KiTextField 
                  placeholder={intl.messages["products.filters.search.placholder"]}
                  type="whiteBg"
                  autoComplete="off"
                  name="search"
                  value={search}
                  handleChange={handleInputChange} 
                > 
                </KiTextField>
              </span>
            </div>
            { selectedCategories.length ?
              <div className={classes.rightWrapper}>
                <div className={classes.noBreakText}>
                  <span>{ selectedCategories.length }</span>
                  <Translation id={selectedCategories.length > 1 ? "categories.text.blacklist_info_plural" : "categories.text.blacklist_info_singular"}></Translation>
                </div>
                { isWhitelisted ? <CustomButton
                  size={"md"}
                  onClick={() => setIsBlacklistConfirmationPopupOpen(true)}
                >
                  <p>{intl.messages["customers.blacklist.button"]}</p>
                </CustomButton> : null }
                { isBlacklisted ? <CustomButton
                  size={"md"}
                  onClick={() => whitelistCategories()}
                >
                  <p>{intl.messages["customers.whitelist.button"]}</p>
                </CustomButton> : null }
              </div> : null
            }
          </div>
            
          <KiTable
            columns={columns}
            rows={tableData}
            onSelect={onSelectCategory}
            onSelectAll={onSelectAll}
            onClickRow={onClickRow}
            loading={loading}
            total={tableData.length}
            paginationType="item"
            savePagination={savePagination}
            pagination={pagination}
          />
        </div>
      </Dialog>
      
      {/* -- BLACKLIST CONFIRMATION POPUP -- */}
      <Dialog
        isOpen={isBlacklistConfirmationPopupOpen}
        close={close}
        style={{ padding: "60px 80px" }}
        PaperProps={{ style: { borderRadius: 15 } }}
        closeStyle={null}
      >
        <div className={classes.container}>
          <h4 className={classes.title}>
            <Translation id={selectedCategories.length > 1 ? "category.blacklist.plural_title" : "category.blacklist.title"} />
          </h4>
          <div className={`${classes.flexColumn} ${classes.descriptionText}`}>
            <div><Translation id="category.blacklist.description_1" /></div>
            <div><Translation id="category.blacklist.description_2" /></div>
          </div>
          <div className={classes.buttonsContainer}>
            <CustomButton
              type="secondary"
              size="sm"
              onClick={() => setIsBlacklistConfirmationPopupOpen(false)}
            >
              <Translation id="button.anulation" />
            </CustomButton>
            <CustomButton
              type="primary"
              size="sm"
              onClick={() => blacklistCategories()}
            >
              <Translation id="button.blacklist.category" />
            </CustomButton>
          </div>
        </div>
      </Dialog>
    </>
  );
};