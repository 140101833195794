
import { useEffect, useMemo, useState } from "react";
import Tooltip from "@material-ui/core/Tooltip";

import { Translation } from "../Translation";
import { PageSelectorArrow, PageSelectorKeys } from "../KiPagination/KiPagination";
import { KiCheckbox } from "../KiCheckbox/KiCheckbox";

import classes from "./Table.module.scss";

const rowsPerPage = 10;

export const Table = ({ columns, rows, total, loadMore, isLoading, defaultOrder, emptyComponent, onSelect, onSelectAll, isAllSelected }) => {

  const [page, setPage] = useState(0);
  const [order, setOrder] = useState(defaultOrder);

  useEffect(() => {

    const nbNextPage = (page + 2) * rowsPerPage;

    if (!isLoading && rows.length && nbNextPage > rows.length && rows.length < total) {
      loadMore();
    }
  }, [page, total, rows, loadMore, isLoading]);

  const onTableHeaderCellClick = (key) => {
    const newOrder = { key, type: "asc" };

    if (order && order.key === key && order.type === "asc") {
      newOrder.type = "desc";
    }
    
    setOrder(newOrder);
  };

  const sortedRows = useMemo(() => {
    if (!order) {
      return [...rows];
    } else {

      return [...rows].sort((a, b) => {
        const valueA = a[order.key];
        const valueB = b[order.key];
        let result;

        if (typeof valueA === "string") {
          result = valueA.localeCompare(valueB, undefined, { numeric: true, sensitivity: "base" });
        }

        if (valueA instanceof Date || valueB instanceof Date) {
          result = (valueA?.getTime() || 0) - (valueB?.getTime() || 0);
        }

        if (typeof valueA === "number") {
          result = valueA - valueB;
        }

        return order.type === "asc" ?  result : -result;
      });
    }
  }, [rows, order]);

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <table className={classes.table}>
          <thead className={classes.tableHeader}>
            <tr>
              { onSelect ? <th className={classes.tableHeaderCell}><KiCheckbox backgroundColor={"#FFD481"} onChange={() => { onSelectAll(); }} checked={isAllSelected}></KiCheckbox></th> : null }
              {columns.map(column => (
                <th 
                  className={classes.tableHeaderCell} 
                  key={column.key}
                  onClick={() => column.key ? onTableHeaderCellClick(column.key) : null}
                >
                  <Tooltip disableHoverListener={!column.tooltip} title={column.tooltip} arrow classes={{ tooltip: classes.tooltip, arrow: classes.tooltipArrow }}>
                    <span>
                      {column.name}
                      {order?.key === column.key &&
                        <i className={`${classes.orderArrow} fal fa-long-arrow-${order.type === "asc" ? "up" : "down"}`} />
                      }
                    </span>
                  </Tooltip>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {sortedRows.slice(page * rowsPerPage, (page + 1) * rowsPerPage).map((row, idx) => (
              <tr key={idx} className={classes.tableRow}>
                { onSelect ? <td className={classes.tableContentCell}><KiCheckbox backgroundColor={"#FFD481"} onChange={() => onSelect(row)} checked={row.isSelected}></KiCheckbox></td> : null }
                {columns.map(column => (
                  <td key={column.key} className={classes.tableContentCell} >
                    {column.render ? column.render(row) : row[column.key] || ""}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* PAGINATION */}

      {!!rows.length &&
        <div className={classes.pagination}>
          <div className="info">
            <Translation id="table.pagination.page" />&nbsp;
            {page + 1}-{Math.ceil(total / rowsPerPage)}
          </div>
          <div className={classes.paginationButtons}>
            <PageSelectorArrow
              direction={"left"}
              isDisabled={page === 0}
              pageKey={page}
              onPageChange={setPage}
            />
            <PageSelectorKeys
              pageKey={page}
              maxPageKey={Math.ceil(total / rowsPerPage) - 1}
              onPageChange={setPage}
            />
            <PageSelectorArrow
              direction={"right"}
              isDisabled={page === Math.ceil(total / rowsPerPage) - 1 }
              pageKey={page}
              onPageChange={setPage}
            />
          </div>
        </div>
      }

      {!rows.length && 
        emptyComponent
      }
    </div>
  );
};