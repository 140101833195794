import { useEffect, useState, useMemo, useCallback } from "react";
import { useIntl } from "react-intl";
import classNames from "classnames";
import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";

import { Dialog } from "../../common/Dialog";
import { Translation } from "../../common/Translation";
import { Table } from "../../common/Table/Table";
import { SelectPicker } from "../../common/SelectPicker";
import { KiTextField } from "../../common/Input/KiTextField";
import { CustomButton } from "../../common/Button/CustomButton";
import CircularProgress from "@material-ui/core/CircularProgress";

import { useAuth } from "../../../hooks/useAuth";
import classes from "./BlacklistCustomerGroupsPopup.module.scss";

import noDataBackground from "../../../public/no-data.png";

export const BlacklistCustomerGroupsPopup = ({ isOpen, close, resetData }) => {
  const [rows, setRows] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [statuses, setStatuses] = useState([
    { isActive: true, key: "visible" },
    { isActive: true, key: "blacklisted" }
  ]);
  const [pagination, setPagination] = useState({
    rowsPerPage: 10,
    pageKey: 0,
    page: 0
  });
  const [search, setSearch] = useState("");
  const [debouncedSearch, setDebouncedSearch] = useState("");
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [isBlacklisted, setIsBlacklisted] = useState(false);
  const [isWhitelisted, setIsWhitelisted] = useState(false);
  const [isBlacklistConfirmationPopupOpen, setIsBlacklistConfirmationPopupOpen] = useState(false);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);

  const auth = useAuth();
  const intl = useIntl();

  const searchSubject = useMemo(() => new Subject(), []);

  useEffect(() => {
    const subscription = searchSubject.pipe(debounceTime(500)).subscribe((value) => {
      setDebouncedSearch(value);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [searchSubject]);

  useEffect(() => {
    const checkBlacklist = () => {
      const result = selectedGroups.some(group => 
        rows.find(row => row.id_group === group.id_group && row.blacklist_date)
      );
      setIsBlacklisted(result);
    };

    checkBlacklist();
  }, [selectedGroups, rows]); 

  useEffect(() => {
    const checkWhitelist = () => {
      const result = selectedGroups.some(group => 
        rows.find(row => row.id_group === group.id_group && row.blacklist_date === null)
      );
      setIsWhitelisted(result);
    };

    checkWhitelist();
  }, [selectedGroups, rows]); 

  const columns = [
    { key: "default_customer_group", name: intl.messages["popupBlacklistCustomerGroups.table.head.customerGroup"], render: row => row.default_customer_group },
    { key: "total", name: intl.messages["popupBlacklistCustomerGroups.table.head.nbContacts"], render: row => row.total },
    { key: "iscustomergroupblacklisted", name: intl.messages["popupBlacklistCustomerGroups.table.head.status"], render: row => <div className={classes.statusWrapper}><div className={`${classes.statusIcon} ${ !row.iscustomergroupblacklisted ? classes.greenIcon : classes.redIcon}`}></div><div>{ !row.iscustomergroupblacklisted ? intl.messages["popupBlacklistCustomerGroups.status.visible"] : intl.messages["popupBlacklistCustomerGroups.status.blacklisted"] }</div></div> },
    { key: null, name: "", render: row => <div className={classes.seeAllGroups} onClick={(event) => { event.stopPropagation(); close(row.id_group); }}>{intl.messages["popupBlacklistCustomerGroups.table.seeAllContacts"]}</div> },
  ];

  const emptyComponent = (
    <div className={classes.emptyState}>
      <img src={noDataBackground} alt="" height={200} />
      <div className="text"><Translation id="table.noData" /></div>
    </div>
  );

  const loadData = useCallback(() => {
    let url = "/api/data/getCustomerGroupsData?limit=10000&page=0&";

    if (debouncedSearch) {
      url += `searchText=${debouncedSearch}&`;
    }

    const activeStatus = getActiveFilters(statuses);
    if (activeStatus.length !== statuses.length) {
      activeStatus
        .forEach((activeFilter, index) => {
          url += `statuses=${activeFilter.key}&`;
        });
    }

    auth.fetch(url)
      .then(
        response => {
          const formattedData = response.map(row => {
            return {
              ...row,
              isChecked: false,
            };
          });
          setRows(response);
          setTableData(formattedData);
          setLoading(false);
      
        }
      );
  }, [debouncedSearch, statuses, auth]);

  useEffect(() => {
    loadData();
  }, [debouncedSearch, loadData, statuses]);

  const onSelect = (row) => {
    const newTableData = tableData.map(rowData => {
      if (rowData.id_group === row.id_group) {
        return {
          ...rowData,
          isSelected: !rowData.isSelected,
        };
      }
      return rowData;
    });

    setTableData(newTableData);
    setSelectedGroups(newTableData.filter(row => row.isSelected));
  };

  const onSelectAll = () => {
    const newTableData = tableData.map(row => {
      return {
        ...row,
        isSelected: !isAllSelected,
      };
    });

    setTableData(newTableData);
    setSelectedGroups(newTableData.filter(row => row.isSelected));
    setIsAllSelected(!isAllSelected);
  };

  function getActiveFilters(filterObject) {
    if (!filterObject) { return; }
    return filterObject
      .filter(x => x.isActive);
  };

  const handleInputChange = (event) => {
    setPagination({ ...pagination, page: 0, pageKey: 0 });
    setSearch(event.target.value);
    searchSubject.next(event.target.value);
  };

  function switchStatuses(name, value) {
    const newStatuses = JSON.parse(JSON.stringify(statuses));

    newStatuses.forEach((newStatus) => {
      newStatus.isActive = value.includes(intl.messages[`categories.filters.status.${newStatus.key}`]);
    });

    setStatuses(newStatuses);
    setPagination({ ...pagination, page: 0, pageKey: 0 });
  };

  const blacklistGroups = () => {
    setActionLoading(true);
    auth.fetch("/data/blacklistCustomersGroups", 
      { 
        method: "POST", 
        body: JSON.stringify({
          groupIds: selectedGroups.map(group => group.id_group)
        })
      })
      .then(() => {
        loadData();
        setSelectedGroups([]);
        resetData();
        setIsBlacklistConfirmationPopupOpen(false);
        setIsAllSelected(false);
        setActionLoading(false);
      });
  };

  const whitelistGroups = () => {
    setActionLoading(true);
    auth.fetch("/data/whitelistCustomersGroups", 
      { 
        method: "POST", 
        body: JSON.stringify({
          groupIds: selectedGroups.map(group => group.id_group)
        })
      })
      .then(() => {
        loadData();
        setSelectedGroups([]);
        resetData();
        setIsAllSelected(false);
        setActionLoading(false);
      });
  };
  
  return (
    <Dialog
      PaperProps={{ style: { borderRadius: 40, width: "85%", marginLeft: "15%" } }}
      isOpen={isOpen}
      close={() => { close(); }}
    >
      <div>
        <div className={classes.title}><Translation id="popupBlacklistCustomerGroups.title" /></div>
      </div>
      <div className={classNames(classes.subTitle,classes.MT35)}><Translation id="popupBlacklistCustomerGroups.subTitle" /> : 
        { 
          !loading && rows?.length ? 
            ` ${rows.filter(row => row.blacklist_date !== null).length} / ${rows.length}` :
            " -"
        }
      </div>
      <div className={classNames(classes.rowWrapper, classes.MT45)}>
        <div className={classes.rowWrapper}>
          <span>{intl.messages["popupBlacklistCustomerGroups.customerGroup"]}</span>
          <div className={
            classNames(
              classes.pathSubCategorieTotal, 
              classes.selectedPath
            )
          }>
            { rows.length }
          </div>
        </div>
      </div>
      <div className={classes.separator}></div>

      {/* -- CTAS -- */}
      <div className={classes.ctas}>
        <div className={classes.leftWrapper}>
          <SelectPicker
            selectList={statuses.map(status => intl.messages[`categories.filters.status.${status.key}`])}
            selected={statuses.filter(status => status.isActive).map(status => intl.messages[`categories.filters.status.${status.key}`] || status.key)}
            action={(name, value) => switchStatuses(name, value)}
            displayName={intl.messages["products.table.head.status"]}
          />

          <span className={classes.spacedCta15}>
            <KiTextField 
              placeholder={intl.messages["products.filters.search.placholder"]}
              type="whiteBg"
              autoComplete="off"
              name="search"
              value={search}
              handleChange={handleInputChange} 
            > 
            </KiTextField>
          </span>
        </div>

        { selectedGroups.length ?
          <div className={classes.rightWrapper}>
            <div className={classes.noBreakText}>
              <span>{ selectedGroups.length }</span>
              <Translation id={selectedGroups.length > 1 ? "customer_group.text.blacklist_info_plural" : "customer_group.text.blacklist_info_singular"}></Translation>
            </div>
            { isWhitelisted ? <CustomButton
              size={"md"}
              onClick={() => setIsBlacklistConfirmationPopupOpen(true)}
            >
              <p>{intl.messages["customers.blacklist.button"]}</p>
            </CustomButton> : null }
            { isBlacklisted ? <CustomButton
              size={"md"}
              onClick={() => whitelistGroups()}
            >
              { actionLoading ? <CircularProgress size={24} className={classes.loading} /> : <p>{intl.messages["customers.whitelist.button"]}</p> }
            </CustomButton> : null }
          </div> : null
        }
      </div>

      <Table
        columns={columns}
        rows={tableData}
        total={tableData.length}
        isLoading={loading}
        defaultOrder={{key: "name", type: "desc"}}
        emptyComponent={emptyComponent}
        onSelect={onSelect}
        onSelectAll={onSelectAll}
        isAllSelected={isAllSelected}
      />

      {/* -- BLACKLIST CONFIRMATION POPUP -- */}
      <Dialog
        isOpen={isBlacklistConfirmationPopupOpen}
        close={close}
        style={{ padding: "60px 80px" }}
        PaperProps={{ style: { borderRadius: 15 } }}
        closeStyle={null}
      >
        <div className={classes.container}>
          <h4 className={classes.title}>
            <Translation id={selectedGroups.length > 1 ? "customer_group.blacklist.plural_title" : "customer_group.blacklist.title"} />
          </h4>
          <div className={`${classes.flexColumn} ${classes.descriptionText}`}>
            <div><Translation id="customer_group.blacklist.description_1" /></div>
            <div><Translation id="customer_group.blacklist.description_2" /></div>
          </div>
          <div className={classes.buttonsContainer}>
            <CustomButton
              type="secondary"
              size="sm"
              onClick={() => setIsBlacklistConfirmationPopupOpen(false)}
            >
              <Translation id="button.anulation" />
            </CustomButton>
            <CustomButton
              type="primary"
              size="sm"
              onClick={() => blacklistGroups()}
            >
              { actionLoading ? <CircularProgress size={24} className={classes.loading} /> : <Translation id="button.blacklist.category" /> }
            </CustomButton>
          </div>
        </div>
      </Dialog>
    </Dialog>
  );
};