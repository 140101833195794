import { Translation } from "../../common/Translation";
import classes from "./RightMenuNavigation.module.scss";

export const RightMenuNavigation = ({ selectedMenu, setSelectedMenu }) => {
  
  return (
    <div className={classes.rightMenuNavigation}>

      <div className={`${classes.sideBlock} ${selectedMenu === "element" ? classes.focused : classes.unfocused}`} onClick={() => setSelectedMenu("element")}>
        <i className={`fa-solid fa-grid-2-plus ${classes.sideIcon}`} />
        <div className="text">
          <Translation id="newsletter.rightMenu.element" />
        </div>
      </div>

      <div className={`${classes.sideBlock} ${selectedMenu === "design" ? classes.focused : classes.unfocused}`} onClick={() => setSelectedMenu("design")}>
        <i className={`fa-solid fa-palette ${classes.sideIcon}`} />
        <div className="text">
          <Translation id="newsletter.rightMenu.design" />
        </div>
      </div>
        
    </div>
  );
};