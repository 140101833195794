import { useState, useEffect, useCallback, useContext } from "react";
import { useAuth } from "../../../hooks/useAuth";
import { Translation } from "../../common/Translation";
import { Switch } from "../../common/Input/Switch";
import { SketchPicker } from "react-color";
import { AccountContext } from "../../../contexts/context";
import { TextField } from "../../common/Input/TextField";
import { ImageAlignInput } from "./RightMenuEditContentInputs/ImageAlignInput.jsx";
import { TextDecorationInput } from "./RightMenuEditContentInputs/TextDecorationInput.jsx";
import { BtnRadiusInput } from "./RightMenuEditContentInputs/BtnRadiusInput.jsx";
import { KiDropdown } from "../../common/Input/KiDropdown";
import classes from "./RightMenuDesignContent.module.scss";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

export const RightMenuDesignContent = ({ reloadBlocks, templateId, editor }) => {

  const auth = useAuth();
  const { account } = useContext(AccountContext);
  const [emailBackgroundColor, setEmailBackgroundColor] = useState("");
  const [selectedSubTextMenu, setSelectedSubTextMenu] = useState("title");
  const [designTemplate, setDesignTemplate] = useState(null);
  const [fontSizeValue, setFontSizeValue] = useState(10);
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);
  const [fontFamilyValue, setFontFamilyValue] = useState("Tahoma");
  const [fontColorValue, setFontColorValue] = useState("");
  const [fontAlignValue, setFontAlignValue] = useState("");
  const [lineHeightValue, setLineHeightValue] = useState(1.5);
  const [btnAlignValue, setBtnAlignValue] = useState("");
  const [btnColorValue, setBtnColorValue] = useState("");
  const [btnRadiusValue, setBtnRadiusValue] = useState(10);
  const [btnRadiusCustomValue, setBtnRadiusCustomValue] = useState(4);
  const [btnRadiusType, setBtnRadiusType] = useState("custom");
  const [showPriceProductValue, setShowPriceProductValue] = useState(true);
  const [showDescriptionProductValue, setShowDescriptionProductValue] = useState(true);
  

  //////////// COMPONENTS /////////////

  const PixelInput = () => {
    const handleIncrement = () => { handleFontSizeChange(fontSizeValue + 1); };
    const handleDecrement = () => { handleFontSizeChange(fontSizeValue - 1); };
    return (
      <NumericInput value={fontSizeValue} onChange={handleFontSizeChange}  onIncrement={handleIncrement} onDecrement={handleDecrement}/>
    );
  };
  
  const RadiusInput = () => {
    const handleIncrement = () => { setBtnRadiusCustomValue(btnRadiusCustomValue + 1); };
    const handleDecrement = () => { setBtnRadiusCustomValue(btnRadiusCustomValue - 1); };
    return (
      <NumericInput value={btnRadiusCustomValue} onChange={ setBtnRadiusCustomValue } onIncrement={handleIncrement} onDecrement={handleDecrement}/>
    );
  };

  const NumericInput = ({ value, onChange, disabled, onIncrement, onDecrement, handleBlur }) => {
    const [localValue, setLocalValue] = useState(value);
    const handleBlurInternal = () => {
      localValue ? onChange(Number(localValue)) : setLocalValue(value);
      handleBlur && handleBlur();
    };
    return (
      <div className={classes.radiusContainer}>
        <div className={classes.pixelInputContainer}>
          <TextField
            disabled={disabled}
            value={localValue}
            type="px"
            onChange={event => setLocalValue(parseInt(event.target.value, 10))}
            onBlur={handleBlurInternal}
          />
        </div>
        { !disabled &&
          <div className={classes.arrowsContainer}>
            <div onMouseDown={onIncrement}><i className="fa-solid fa-chevron-up" /></div>
            <div onMouseDown={onDecrement}><i className="fa-solid fa-chevron-down" /></div>
          </div>
        }
        
      </div>
    );
  };
  
  
  const TitleSection = ({ iconClass, translationId }) => {
    return (
      <div className={classes.titleSection}>
        <div className={classes.iconBlock}>
          <i className={iconClass} />
        </div>
        <p><Translation id={translationId} /></p>
      </div>
    );
  };

  const ColorPropertyInput = ({ value, onChange, type }) => {
    const { account } = useContext(AccountContext);
    const auth = useAuth();
    const [_value, setValue] = useState(value);
    const [presetColors, setPresetColors] = useState([]);
  
    useEffect(() => { setValue(value); }, [value]);
  
    useEffect(() => {
      if (_value !== value) {
        const timeout = setTimeout(() => {
          auth.fetch("/account_management/addNewsletterColors", {
            method: "POST",
            body: JSON.stringify({
              colors: [_value],
            })
          });
  
          if (!presetColors.includes(_value)) {
            setPresetColors(prev => [...prev, _value].slice(-26));
          }
          onChange(_value);
        }, 500);
        return () => clearTimeout(timeout);
      }
    }, [_value, onChange, value, auth, presetColors]);

    useEffect(() => {
      if (account && !presetColors.length) {
        const colors = [];
        const regex = /#?[a-f0-9]{6}/;
        account.template_parameters?.forEach(parameter => {
          if (parameter.value.toLowerCase().match(regex)) {
            colors.push(parameter.value);
          }
        });
        setPresetColors([...new Set(colors), ...(account.newsletterSavedColors || [])]);
      }
    }, [account, presetColors]);

    const [isOpen, setIsOpen] = useState();

    return (
      type === "minimal" ?
        <div className={classes.colorPropertyInputMinimalContainer} style={{ backgroundColor: _value }} onClick={() => setIsOpen(true)}>
          {isOpen &&
          <div className={classes.colorPropertyInputColorPickerMinimal}>
            <ClickAwayListener onClickAway={() => setIsOpen(false)}>
              <SketchPicker
                color={_value}
                onChange={({ hex }) => setValue(hex)}
                presetColors={presetColors}
              />
            </ClickAwayListener>
          </div>
          }
        </div>
        :
        <div className={classes.colorPropertyInputContainer} onClick={() => setIsOpen(true)}>
          <div className={classes.colorPropertyInputColorRect} style={{ backgroundColor: type === "button" ? btnColorValue : emailBackgroundColor }} />
          <div className="infoSmall">{_value?.toUpperCase()}</div>
          {isOpen &&
          <div className={classes.colorPropertyInputColorPicker}>
            <ClickAwayListener onClickAway={() => setIsOpen(false)}>
              <SketchPicker
                color={_value}
                onChange={({ hex }) => setValue(hex)}
                presetColors={presetColors}
              />
            </ClickAwayListener>
          </div>
          }
        </div>
    );
  };


  //////////// HOOK /////////////

  useEffect(() => {
    async function setDesignTemplateProps(templateId) {
      try {
        const response = await auth.fetch(`/api/account_management/mjmlDesignTemplate?templateId=${templateId}`);
        if (response.designTemplate) {
          console.log("Design template loaded:", response.designTemplate);
          setDesignTemplate(response.designTemplate);
          const { backgroundColor, showPrice, showDescription } = response.designTemplate;
          setEmailBackgroundColor(backgroundColor);
          setBtnColorValue(response.designTemplate.button?.backgroundColor);
          setBtnRadiusValue(parseInt(response.designTemplate.button?.borderRadius));
          setBtnRadiusCustomValue(parseInt(response.designTemplate.button?.borderRadius));
          setBtnAlignValue(response.designTemplate.button?.align);
          setShowPriceProductValue(showPrice);
          setShowDescriptionProductValue(showDescription);
          setFontColorValue(response.designTemplate.title.color);
          setFontAlignValue(response.designTemplate.title.align);
          setFontFamilyValue(response.designTemplate.title.fontFamily);
          setFontSizeValue(parseInt(response.designTemplate.title.fontSize));
          setIsUnderline(response.designTemplate.title.textDecoration === "underline");
          setIsItalic(response.designTemplate.title.fontStyle === "italic");
          setIsBold(response.designTemplate.title.fontWeight === "bold"); 
        };
      } catch {}
    };
    setDesignTemplateProps(templateId);
  }, [auth,templateId]);

  useEffect(() => {
    if(!designTemplate) return;
    const selectedText = designTemplate[selectedSubTextMenu];
    if (selectedText) {
      setFontColorValue(selectedText?.color);
      setFontAlignValue(selectedText?.align);
      setFontFamilyValue(selectedText?.fontFamily);
      setFontSizeValue(parseInt(selectedText?.fontSize));
      setIsUnderline(selectedText?.textDecoration === "underline");
      setIsItalic(selectedText?.fontStyle === "italic");
      setIsBold(selectedText?.fontWeight === "bold");
    }
  }, [selectedSubTextMenu,designTemplate]);
  

  const handleTextDecorationsChange = (textDecorationsTab) => {   
    textDecorationsTab.forEach(item => {
      let styleToApply = {};
      if(item.name === "bold") {
        const value = item.value ? item.name : "normal" ;
        styleToApply = { "font-weight": value };
        setDesignTemplate(prevTemplate => ({...prevTemplate, [selectedSubTextMenu]: { ...prevTemplate[selectedSubTextMenu], fontWeight: value }}));
        applyStyles(`${selectedSubTextMenu}-style`, styleToApply);
      }
      if(item.name === "italic") {
        const value = item.value ? item.name : "normal" ;
        styleToApply = { "font-style": value };
        setDesignTemplate(prevTemplate => ({...prevTemplate, [selectedSubTextMenu]: { ...prevTemplate[selectedSubTextMenu], fontStyle: value }}));
        applyStyles(`${selectedSubTextMenu}-style`, styleToApply);
      }
      if(item.name === "underline") {
        const value = item.value ? item.name : "none" ;
        styleToApply = { "text-decoration": value };
        setDesignTemplate(prevTemplate => ({...prevTemplate, [selectedSubTextMenu]: { ...prevTemplate[selectedSubTextMenu], textDecoration: value }}));
        applyStyles(`${selectedSubTextMenu}-style`, styleToApply);
      }
    });   
  };

  useEffect(() => {
    if (!designTemplate) return;
    saveDesign();
  }, [designTemplate]);

  const saveDesign = useCallback(() => {
    try {
      auth.fetch("/account_management/saveMjmlDesignTemplate", {
        method: "POST",
        body: JSON.stringify({
          templateId: templateId,
          designTemplate: designTemplate
        }),
      }).then((result) => {
        reloadBlocks(account, result.templateDesign);
      });
    } catch(error) {
      console.error("Error updating template:", error);
    }
  }, [designTemplate, auth, templateId, reloadBlocks]);

  useEffect(() => {
    if(btnRadiusType === "custom") {
      handleBtnRadiusTypeChange(btnRadiusCustomValue);
    }
  }, [btnRadiusType,btnRadiusCustomValue]);


  const handleEmailBackgroundColorChange = (value) => {
    setEmailBackgroundColor(value);
    setDesignTemplate(prevTemplate => ({ ...prevTemplate, backgroundColor: value }));
    applyStyles("template-style", { "background-color": value });
  };

  const handleFontFamilyChange = (value) => {
    setFontFamilyValue(value);
    setDesignTemplate(prevTemplate => ({...prevTemplate, [selectedSubTextMenu]: { ...prevTemplate[selectedSubTextMenu], fontFamily: value }}));
    applyStyles(`${selectedSubTextMenu}-style`, { "font-family": value });
  };

  const handleFontSizeChange = (value) => {
    setFontSizeValue(value);
    setDesignTemplate(prevTemplate => ({...prevTemplate, [selectedSubTextMenu]: { ...prevTemplate[selectedSubTextMenu], fontSize: value + "px" }}));
    applyStyles(`${selectedSubTextMenu}-style`, { "font-size": value + "px" });
  };

  const handleLineHeightChange = (value) => {
    setLineHeightValue(value);
    setDesignTemplate(prevTemplate => ({ ...prevTemplate, [selectedSubTextMenu]: { ...prevTemplate[selectedSubTextMenu], lineHeight: value }}));
    applyStyles("text-style", { "line-height": value });
  };

  const handleFontColorChange = (value) => {
    setFontColorValue(value);
    setDesignTemplate(prevTemplate => ({ ...prevTemplate, [selectedSubTextMenu]: { ...prevTemplate[selectedSubTextMenu], color: value }}));
    applyStyles(`${selectedSubTextMenu}-style`, { "color": value });
  };

  const handleFontAlignChange = (value) => {
    setFontAlignValue(value);
    setDesignTemplate(prevTemplate => ({ ...prevTemplate, [selectedSubTextMenu]: { ...prevTemplate[selectedSubTextMenu], align: value }}));
    applyStyles(`${selectedSubTextMenu}-style`, { "align": value });
  };

  const handleBtnColorChange = (value) => {
    setBtnColorValue(value);
    setDesignTemplate(prevTemplate => ({ ...prevTemplate, button: { ...prevTemplate.button, backgroundColor: value } }));
    applyStyles("button-style", { "background-color": value });
  };

  const handleBtnRadiusTypeChange = (value) => {
    setBtnRadiusValue(value);
    if(designTemplate){
      setDesignTemplate(prevTemplate => ({ ...prevTemplate, button: { ...prevTemplate.button, borderRadius: value } }));
      applyStyles("button-style", { "border-radius": value + "px" });
    }
  };

  const handleBtnAlignChange = (value) => {
    setBtnAlignValue(value);
    setDesignTemplate(prevTemplate => ({ ...prevTemplate, button: { ...prevTemplate.button, align: value } }));
    applyStyles("button-style", { "align": value });
  };

  const handleShowPriceProductChange = (value) => {
    setShowPriceProductValue(value);
    setDesignTemplate(prevTemplate => ({ ...prevTemplate, showPrice: value }));
    applyStyles("product-price-style", { "font-size": value ? "16px" : "0px", "height": value ? "" : "0px", "padding-bottom": value ? "20px" : "0px"});
  };

  const handleShowDescriptionProductChange = (value) => {
    setShowDescriptionProductValue(value);
    setDesignTemplate(prevTemplate => ({ ...prevTemplate, showDescription: value }));
    applyStyles("product-description-style", { "font-size": value ? "12px" : "0px", "height": value ? "" : "0px" , "padding-bottom": value ? "20px" : "0px"});
  };


  //////////// STYLE FCT /////////////

  const applyStyles = useCallback((styleType , styleToApply) => {
    if (!editor || !editor.getComponents().length) { return; }
    const rootComponent = editor.getComponents().at(0);
    if (!rootComponent) { return;}

    const applySelectedStyle = (component, style) => {
      if (!component) return;

      if ((styleType === "text-style" || styleType === "subtitle-style" || styleType === "title-style") && (component.attributes.type === "mj-text" || component.attributes.type === "readonly-mj-text" || component.attributes.type === "text" || component.attributes.type === "readonly-mj-text")) {
        if(component.attributes.attributes["data-text-id"] === selectedSubTextMenu) {
          component.addStyle(style);
        }
      } else if (styleType === "template-style" && (component.attributes.type === "kilibaBlock-mj-wrapper" || component.attributes.type === "kilibaBlock-mj-section")) {
        component.addStyle(style);
      } else if (styleType === "button-style" && component.attributes.type === "mj-button") {
        component.addStyle(style);
      } else if (styleType === "product-price-style" && component.attributes.type === "readonly-mj-text" && component.attributes.attributes["data-product-id"]) {
        component.addStyle(style);
      } else if (styleType === "product-description-style" && component.attributes.type === "mj-text" && component.attributes.attributes["data-text-id"] === "productDescription") {
        component.addStyle(style);
      }
      const childComponents = component.components();
      if (childComponents && childComponents.length) {
        childComponents.forEach(childComponent => {
          applySelectedStyle(childComponent, style);
        });
      }
    };

    applySelectedStyle(rootComponent, styleToApply);
  }, [editor, selectedSubTextMenu]);

  //////////// RENDER /////////////

  return (
    <div className={classes.designContainer}>

      {/* /////////  EMAIL SECTION  /////////// */}
      <TitleSection iconClass="fa-regular fa-envelope" translationId="field.email" />
      <div className={classes.container}>
        <div className={`text ${classes.subContainer}`}>
          <Translation id="newsletter.rightMenu.property.backgroundColor" />
        </div>
        <ColorPropertyInput value={emailBackgroundColor} onChange={handleEmailBackgroundColorChange} />
      </div>

      {/* /////////  TEXT SECTION  /////////// */}
      <TitleSection iconClass="fa-regular fa-text-size" translationId="field.texte" />
      <div className={classes.container}>
        <div className={classes.textRowContent}>
          <div className={`${classes.typeTextContainer} ${selectedSubTextMenu === "title" ? classes.typeTextIsSelected : null} `}>
            <div className={`text ${selectedSubTextMenu === "title" ? classes.subMenuFocused : classes.subMenuUnfocused}`} onClick={() => setSelectedSubTextMenu("title")}>
              <Translation id="blockManager.category.title" />
            </div>
          </div>
          <div className={`${classes.typeTextContainer} ${selectedSubTextMenu === "subtitle" ? classes.typeTextIsSelected : null} `}>
            <div className={`text ${selectedSubTextMenu === "subtitle" ? classes.subMenuFocused : classes.subMenuUnfocused}`} onClick={() => setSelectedSubTextMenu("subtitle")}>
              <Translation id="blockManager.category.subtitle" />
            </div>
          </div>
          <div className={`${classes.typeTextContainer} ${selectedSubTextMenu === "text" ? classes.typeTextIsSelected : null} `}>
            <div className={`text ${selectedSubTextMenu === "text" ? classes.subMenuFocused : classes.subMenuUnfocused}`} onClick={() => setSelectedSubTextMenu("text")}>
              <Translation id="blockManager.category.paragraph" />
            </div>
          </div>
          <div className={`${classes.typeTextContainer} ${selectedSubTextMenu === "button" ? classes.typeTextIsSelected : null} `}>
            <div className={`text ${selectedSubTextMenu === "button" ? classes.subMenuFocused : classes.subMenuUnfocused}`} onClick={() => setSelectedSubTextMenu("button")}>
              <Translation id="blockManager.category.button" />
            </div>
          </div>
        </div>

        <div style={{ gap:"15px", marginTop:"10px" }} className={classes.rowContent}>
          <KiDropdown
            noShadow
            placeholder={"Arial ..."}
            value={fontFamilyValue}
            options={[
              { value: "tahoma", label: "Tahoma", },
              { value: "ubuntu", label: "Ubuntu", },
              { value: "helvetica", label: "Helvetica", },
              { value: "arial", label: "Arial", },
              { value: "comicSansMs", label: "Comic Sans MS", },
              { value: "impact", label: "Impact", },
              { value: "arialBlack", label: "Arial black", },
              { value: "geneva", label: "Geneva", },
              { value: "verdana", label: "Verdana", },
              { value: "georgia", label: "Georgia", },
              { value: "courier", label: "Courier", },
              { value: "trebuchetMs", label: "Trebuchet MS", },
              { value: "timesNewRoman", label: "Times new roman", },
              { value: "palatino", label: "Palatino", }
            ]}
            onChange={handleFontFamilyChange}
          />
          <PixelInput />
        </div>
        
        <div style={{ gap:"15px", marginTop:"5px" }} className={classes.rowContent}>
          <TextDecorationInput isBold={isBold} isItalic={isItalic} isUnderline={isUnderline} handleTextDecorationsChange={ handleTextDecorationsChange }/>
          {
            selectedSubTextMenu !== "button" &&
            <ImageAlignInput value={ fontAlignValue } onChange={handleFontAlignChange}/>
          }
          <ColorPropertyInput value={ fontColorValue } onChange={handleFontColorChange} type="minimal" />
        </div>

        {
          selectedSubTextMenu !== "button" &&
            <div style={{ gap: "10px", marginTop:"5px" }} className={`text ${classes.rowContent}`}>
              <Translation id="field.interline" />
              <div className={classes.lineHeightDropdown}>
                <KiDropdown
                  noShadow
                  value={lineHeightValue}
                  options={[
                    { value: 0.5, label: "0.5", },
                    { value: 1, label: "1", },
                    { value: 1.5, label: "1.5", },
                    { value: 2, label: "2", },
                    { value: 2.5, label: "2.5", },
                  ]}
                  onChange={handleLineHeightChange}
                />
              </div>
            
            </div>
        }
      </div>

      {/* /////////  BUTTON SECTION  /////////// */}
      <TitleSection iconClass="fa-regular fa-rectangle-wide" translationId="field.button" />
      <div className={classes.container}>
      
        <div style={{ gap:"10px"}} className={classes.rowContent}>
          <div className={`text ${classes.subContainer}`}>
            <Translation id="newsletter.rightMenu.property.imageAlign" />
            <ImageAlignInput value={btnAlignValue} onChange={handleBtnAlignChange}/>
          </div>
          <div style={{ width:"100%" }} className={`text ${classes.subContainer}`}>
            <Translation id="newsletter.rightMenu.propertyGroup.color" />
            <ColorPropertyInput value={ btnColorValue } onChange={ handleBtnColorChange } type="button"/>
          </div>
        </div>

        <div style={{ marginTop:"10px" }} className={`text ${classes.subContainer}`}>
          <Translation id="newsletter.rightMenu.propertyGroup.radius.button" />
          <BtnRadiusInput onChange={handleBtnRadiusTypeChange} setBtnRadiusType={setBtnRadiusType} btnRadiusType={btnRadiusType} />    
        </div>
        {
          btnRadiusType !== "custom" &&  <NumericInput value={btnRadiusValue} disabled />
        }
        {
          btnRadiusType === "custom" &&  <RadiusInput value={btnRadiusCustomValue} onChange={setBtnRadiusCustomValue} />
        }
      </div>

      {/* /////////  PRODUCT SECTION  /////////// */}
      <TitleSection iconClass="fa-regular fa-box-open" translationId="field.product" />
      <div className={classes.container}>
        <div className={classes.switchContainer}>
          <Switch onChange={event => handleShowPriceProductChange(event.target.checked)} checked={showPriceProductValue}/>
          <div className={`text ${classes.subContainer}`}><Translation id="templateCustomization.show_prices" /></div>
        </div>
        <div className={classes.switchContainer}>
          <Switch onChange={event => handleShowDescriptionProductChange(event.target.checked)} checked={showDescriptionProductValue} />
          <div className={`text ${classes.subContainer}`}><Translation id="templateCustomization.show_description" /></div>
        </div>
      </div>

    </div>
  );
};