import { extractWordingsFromMjTextComponent } from "../utils";
import { title } from "./title";
import { subtitle } from "./subtitle";
import { paragraph1 } from "./paragraph1";
// import { paragraph2 } from "./paragraph2";
import { paragraph3 } from "./paragraph3";
import { paragraph4 } from "./paragraph4";
import { paragraph5 } from "./paragraph5";
import { paragraph6 } from "./paragraph6";
import { paragraph7 } from "./paragraph7";
import { paragraph8 } from "./paragraph8";

export const defaultWordings = {
  title: {
    fr: "Ceci est un titre",
    en: "This is a title",
    es: "Este es un título",
    de: "Dies ist ein Titel",
    nl: "Dit is een titel",
    it: "Questo è un titolo",
    pt: "Este é um título",
  },
  subtitle: {
    fr: "Ceci est un sous-titre un peu plus long que le titre",
    en: "This is a slightly longer subtitle than the title",
    es: "Se trata de un subtítulo ligeramente más largo que el título",
    de: "Dies ist ein Untertitel, der etwas länger als der Titel ist",
    nl: "Dit is een iets langere ondertitel dan de titel",
    it: "Si tratta di un sottotitolo leggermente più lungo del titolo",
    pt: "Trata-se de um subtítulo ligeiramente mais longo do que o título",
  },
  text: {
    fr: "Ceci est un paragraphe. Ceci est encore un paragraphe. Ceci est toujours un paragraphe. Ceci sera indéfiniment un paragraphe. Ceci est irrémédiablement un paragraphe.",
    en: "This is a paragraph. This is another paragraph. This is always a paragraph. This will indefinitely be a paragraph. This is irrevocably a paragraph.",
    es: "Esto es un párrafo. Esto es otro párrafo. Esto es siempre un párrafo. Esto será un párrafo indefinidamente. Esto es irrevocablemente un párrafo.",
    de: "Dies ist ein Absatz. Dies ist ein weiterer Absatz. Dies ist immer ein Absatz. Dies wird auf unbestimmte Zeit ein Absatz sein. Dies ist unwiederbringlich ein Absatz.",
    nl: "Dit is een paragraaf. Dit is nog een paragraaf. Dit is altijd een paragraaf. Dit zal voor altijd een alinea zijn. Dit is onherroepelijk een alinea.",
    it: "Questo è un paragrafo. Questo è un altro paragrafo. Questo è sempre un paragrafo. Questo sarà un paragrafo all'infinito. Questo è irrevocabilmente un paragrafo.",
    pt: "Isto é um parágrafo. Este é outro parágrafo. Isto é sempre um parágrafo. Isto será um parágrafo indefinidamente. Isto é irrevogavelmente um parágrafo.",
  },
};

export const getTextBlocks = ({ dominantColor, accentColor, unsplashPhotos, titleFontProps, subtitleFontProps, textFontProps, buttonFontProps }) => {

  return [
    title({ titleFontProps , title: defaultWordings.title, backgroundColor: dominantColor }),
    title({ titleFontProps, title: defaultWordings.title, backgroundColor: accentColor, fontSize: 14, sectionPadding: 0, fontWeight: 400 }),

    subtitle({ titleFontProps, subtitleFontProps, backgroundColor: dominantColor, title: defaultWordings.title, subtitle: defaultWordings.subtitle }),
    subtitle({ titleFontProps, subtitleFontProps, backgroundColor: dominantColor, title: defaultWordings.title, subtitle: defaultWordings.subtitle }, "left"),

    paragraph8({ titleFontProps, subtitleFontProps, textFontProps, accentColor, backgroundColor: dominantColor, title: defaultWordings.title, subtitle: defaultWordings.subtitle, text: defaultWordings.text }),
    paragraph1({ titleFontProps, textFontProps, text: defaultWordings.text, backgroundColor: dominantColor, title: defaultWordings.title }),
    // paragraph2({ titleFontProps, subtitleFontProps, textFontProps, generatedWordings, dominantColor }),
    paragraph3({ titleFontProps, textFontProps, buttonFontProps, buttonColor: accentColor, text: defaultWordings.text, backgroundColor: dominantColor, title: defaultWordings.title }),
    paragraph4({ textFontProps, buttonFontProps, buttonColor: accentColor, text: defaultWordings.text, backgroundColor: dominantColor }),
    paragraph7({ textFontProps, text: defaultWordings.text, backgroundColor: dominantColor }),

    ...(unsplashPhotos?.length ? [
      paragraph5({ textFontProps, imageUrl: unsplashPhotos[0].url, backgroundColor: dominantColor, text: defaultWordings.text }),
      paragraph6({ textFontProps, imageUrl: unsplashPhotos[0].url, backgroundColor: dominantColor, text: defaultWordings.text }),
    ] : []),
  ];
};

export const extractContentFromTextComponent = (component, wordings, language) => {

  const content = {};
  const textComponents = component.findType("mj-text");
  const buttonComponents = component.findType("mj-button");
  const imageComponents = component.findType("mj-image");

  textComponents.forEach(textComponent => {
    if (textComponent.attributes.attributes["data-text-id"] === "title") {
      content.title = extractWordingsFromMjTextComponent(textComponent, wordings, language);
    }
    if (textComponent.attributes.attributes["data-text-id"] === "subtitle") {
      content.subtitle = extractWordingsFromMjTextComponent(textComponent, wordings, language);
    }
    if (textComponent.attributes.attributes["data-text-id"] === "text") {
      content.text = extractWordingsFromMjTextComponent(textComponent, wordings, language);
    }
  });

  if (buttonComponents?.length) {
    content.buttonText = extractWordingsFromMjTextComponent(buttonComponents[0], wordings, language);
    content.buttonHref = buttonComponents[0].attributes.attributes.href;
    content.buttonColor = buttonComponents[0].attributes.attributes["background-color"];
  }

  if (imageComponents?.length) {
    content.imageUrl = imageComponents[0].attributes.attributes.src;
    content.imageHref = imageComponents[0].attributes.attributes.href || "";
    content.imageAlt = imageComponents[0].attributes.attributes.alt || "";
  }

  content.backgroundColor = component.attributes.attributes["background-color"];

  if (component.attributes.attributes["data-text-product-ids"]) {
    content.productIds = component.attributes.attributes["data-text-product-ids"].split(",");
  } else {
    content.productIds = [];
  }

  return content;
};