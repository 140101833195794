import { useEffect, useState } from "react";
import classNames from "classnames";
import { useIntl } from "react-intl";
import classes from "./PopupProductsCategories.module.scss";

export const Breadcrumb = ({ initialRows, rows, debouncedSearch, loading, onClickRow, lastRowClicked, needsReset, setBreadcrumbNeedsReset, currentPath, setCurrentPath }) => {
  const intl = useIntl();
  const [previousSearch, setPreviousSearch] = useState(null);
  const [previousClickedRow, setPreviousClickedRow] = useState(null);

  useEffect(() => {
    if((rows.length && (currentPath.length === 0 || needsReset))) {
      setCurrentPath([
        { id: -1, name: intl.messages["popupProductsCategories.rootCategory"], sub_categories_total: initialRows?.length || "-" },
      ]);
      setBreadcrumbNeedsReset(false);
    }
  }, [rows, currentPath, intl.messages, needsReset, setBreadcrumbNeedsReset, initialRows, lastRowClicked, setCurrentPath]);

  useEffect(() => {
    if (previousSearch !== debouncedSearch) {
      if(debouncedSearch && !currentPath.find(path => path.id === - 2)) {
        const pathClone = JSON.parse(JSON.stringify(currentPath));
        pathClone.push(
          { id: -2, name: `${intl.messages["popupProductsCategories.breadcrumb.search"]} : ${debouncedSearch} `, sub_categories_total: rows?.length || "-" },
        );

        setCurrentPath(pathClone);
      }
    }
    
    if(debouncedSearch && currentPath.find(path => path.id === -2) && currentPath[currentPath.length - 1].sub_categories_total !== rows?.length && rows?.length > 0) {
      const pathClone = JSON.parse(JSON.stringify(currentPath));
      pathClone[pathClone.length - 1].name = `${intl.messages["popupProductsCategories.breadcrumb.search"]} : ${debouncedSearch} `;
      pathClone[pathClone.length - 1].sub_categories_total = rows?.length || "-";

      setCurrentPath(pathClone);
    };

    setPreviousSearch(debouncedSearch);
  }, [debouncedSearch, intl.messages, rows, currentPath, previousSearch, lastRowClicked, setCurrentPath]);

  useEffect(() => {
    if(lastRowClicked && lastRowClicked.id !== -1 && previousClickedRow !== lastRowClicked && lastRowClicked.sub_categories_total > 0) {
      const pathClone = JSON.parse(JSON.stringify(currentPath));
      // replace search step with clicked row
      if (currentPath.find(path => path.id === -2)) {
        const index = pathClone.findIndex(path => path.id === -2);
        pathClone.splice(index, 1);
        pathClone.push(
          { id: lastRowClicked.id, name: lastRowClicked.name[intl.locale], sub_categories_total: lastRowClicked.sub_categories_total },
        );
        setCurrentPath(pathClone);
      } else {
        // replace last row clicked with new one
        if (currentPath.find(path => path.id === lastRowClicked.id)) {
          const index = pathClone.findIndex(path => path.id === lastRowClicked.id);
          pathClone.splice(index + 1, pathClone.length - index);
          setCurrentPath(pathClone);
        } else {
        // add new row to path
          pathClone.push(
            { id: lastRowClicked.id, name: lastRowClicked.name[intl.locale], sub_categories_total: lastRowClicked.sub_categories_total },
          );
          setCurrentPath(pathClone);
        }
      }
    }
      
    setPreviousClickedRow(lastRowClicked);
  }, [currentPath, intl.locale, lastRowClicked, previousClickedRow, rows, setCurrentPath]);

  return (
    <div className={classNames(classes.rowWrapper, classes.MT45)}>
      { 
        currentPath && currentPath.map((subPath, index) => (
          <>
            <div className={classes.rowWrapper} onClick={() => index < currentPath.length - 1 && !loading ? onClickRow(subPath) : null}>
              <span className={classes.cursorPointer}>{ subPath.name }</span>
              <div className={
                classNames(
                  classes.pathSubCategorieTotal, 
                  index < currentPath.length - 1 ? classes.unselectedPath : classes.selectedPath,
                  classes.cursorPointer
                )
              }>
                { subPath.sub_categories_total }
              </div>
            </div>
            <span className={classes.icon}>{ index < currentPath.length - 1 ? <i className="fa-solid fa-chevron-right"></i> : null}</span>
          </>
        )) 
      }
    </div>
  );
};