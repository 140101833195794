
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import moment from "moment-timezone";
import { useIntl } from "react-intl";
import { convertArrayToCSV } from "convert-array-to-csv";

import { Translation } from "../common/Translation";
import { Table } from "../common/Table/Table";
import { useAuth } from "../../hooks/useAuth";
import { AccountContext } from "../../contexts/context";
import { getFormattedNumber } from "../../tools/getFormattedNumber";
import { EventSystem } from "../../eventsystem/EventSystem.js";
import { CustomButton } from "../common/Button/CustomButton";
import { Switch } from "../common/Input/Switch";

import noDataBackground from "../../public/no-data.png";
import { DashboardGraphs } from "./DashboardGraphs.jsx";

import classes from "./DashboardTables.module.scss";

const numberWithSpaces = (x) => {
  // add space in numbers
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

const ORDERS_LIMIT = 3000;

export const DashboardTables = ({ dayBegin, dayEnd, selectedUsecases, usecasesList, forceReload }) => {

  const auth = useAuth();
  const intl = useIntl();

  const { account, config } = useContext(AccountContext);
  const [selectedTab, setSelectedTab] = useState("campaigns");
  const [campaignsStats, setCampaignStats] = useState();
  const [orders, setOrders] = useState([]);
  const [totalOrders, setTotalOrders] = useState(0);
  const [isOrderLoading, setIsOrderLoading] = useState(false);
  const [tableKey, setTableKey] = useState(1);
  const [usecasesOrderData, setUsecasesOrderData] = useState();
  const [usecasesOrderDataIsLoading, setUsecasesOrderDataIsLoading] = useState();
  const [chartType, setChartType] = useState("ca");

  const usecases = useMemo(() => {
    const usecases = [...selectedUsecases];
    if (selectedUsecases.includes("winterSales")) {
      selectedUsecases.push("solde");
    }
    return usecases;
  }, [selectedUsecases]);

  const renderCategoryCell = (row) => {
    switch (row.category) {
      case "trafic": return <div className={classes.categoryTag} style={{ background: "#D4F1AF" }}><Translation id="dashboard.campaigns.type.trafic" /></div>;
      case "conversion": return <div className={classes.categoryTag} style={{ background: "#FFC5AC" }}><Translation id="dashboard.campaigns.type.conversion" /></div>;
      case "reactivation": return <div className={classes.categoryTag} style={{ background: "#FCE" }}><Translation id="dashboard.campaigns.type.reactivation" /></div>;
      case "events": return <div className={classes.categoryTag} style={{ background: "#D1D3FF" }}><Translation id="dashboard.campaigns.type.events" /></div>;
      case "newsletter": return <div className={classes.categoryTag} style={{ background: "#FFE7B9" }}><Translation id="dashboard.campaigns.type.newsletter" /></div>;
      case "customs": return <div className={classes.categoryTag} style={{ background: "#DDDAD5" }}><Translation id="dashboard.campaigns.type.customs" /></div>;
      default: return <Translation id={`dashboard.campaigns.type.${row.category}`} />;
    }
  };

  const renderCACell = useCallback((row) => {
    if (row.ca === "ignored") {
      return <Translation id="customers.ca.ignored" />;
    }
    return getFormattedNumber(
      row.ca,
      account.currency, intl.locale
    );
  }, [account, intl]);

  const renderOrderUsecaseCell = row => {

    switch (row.category) {
      case "trafic": return <div className={classes.orderUsecaseCell}>{row.usecase} <div className={classes.orderUsecaseUnderline} style={{ background: "#D4F1AF" }} /></div>;
      case "conversion": return <div className={classes.orderUsecaseCell}>{row.usecase} <div className={classes.orderUsecaseUnderline} style={{ background: "#FFC5AC" }} /></div>;
      case "reactivation": return <div className={classes.orderUsecaseCell}>{row.usecase} <div className={classes.orderUsecaseUnderline} style={{ background: "#FCE" }} /></div>;
      case "events": return <div className={classes.orderUsecaseCell}>{row.usecase} <div className={classes.orderUsecaseUnderline} style={{ background: "#D1D3FF" }} /></div>;
      case "newsletter": return <div className={classes.orderUsecaseCell}>{row.usecase} <div className={classes.orderUsecaseUnderline} style={{ background: "#FFE7B9" }} /></div>;
      case "customs": return <div className={classes.orderUsecaseCell}>{row.usecase} <div className={classes.orderUsecaseUnderline} style={{ background: "#DDDAD5" }} /></div>;

      default: return row.usecase;
    }
  };

  const campaignsColumns = useMemo(() => [
    { key: "name", name: intl.messages["dashboard.campaigns.table.head.campaign"] },
    { key: "category", name: intl.messages["dashboard.campaigns.table.head.type"], render: renderCategoryCell },
    { key: "sentDate", name: intl.messages["dashboard.campaigns.table.head.sentDate"], render: row => row.sentDate ? moment(row.sentDate).format("DD/MM/YYYY") : "--" },
    { key: "emails", name: intl.messages["dashboard.campaigns.table.head.emails"], render: row => numberWithSpaces(getFormattedNumber(row.emails, "", intl.locale)) },
    { key: "open", name: intl.messages["dashboard.campaigns.table.head.open"], render: row => `${row.open}%`, tooltip: intl.messages["dashboard.campaigns.table.head.open.tooltip"] },
    { key: "click", name: intl.messages["dashboard.campaigns.table.head.click"], render: row => `${row.click}%`, tooltip: intl.messages["dashboard.campaigns.table.head.click.tooltip"] },
    { key: "reactivity", name: intl.messages["dashboard.campaigns.table.head.reactivity"], render: row => `${row.reactivity}%`, tooltip: intl.messages["dashboard.campaigns.table.head.reactivity.tooltip"] },
    { key: "unsubscribe", name: intl.messages["dashboard.campaigns.table.head.unsubscribe"], render: row => `${row.unsubscribe}%`, tooltip: intl.messages["dashboard.campaigns.table.head.unsubscribe.tooltip"] },
    { key: "hardbounce", name: intl.messages["dashboard.campaigns.table.head.hardBounce"], render: row => `${row.hardbounce}%`, tooltip: intl.messages["dashboard.campaigns.table.head.hardBounce.tooltip"] },
  ], [intl]);

  const orderColumns = useMemo(() => [
    account.CMS === "magento" ?
      { key: "reference", name: intl.messages["orders.table.head.reference"] } :
      { key: "id_order", name: intl.messages["orders.table.head.id"] },
    { key: "ca", name: intl.messages["orders.table.head.amount"], render: renderCACell },
    { key: "email", name: intl.messages["orders.table.head.email"] },
    ...(config?.customersGroupManagement ? [{ key: "default_customer_group", name: intl.messages["customers.table.head.group"] }] : []),
    { key: "last_open", name: intl.messages["orders.table.head.openDate"], render: row => moment(row.last_open).format("DD/MM/YYYY HH:mm:ss") },
    { key: "date_order", name: intl.messages["orders.table.head.orderDate"], render: row => moment(row.date_order).format("DD/MM/YYYY HH:mm:ss") },
    { key: "usecase", name: intl.messages["orders.table.head.usecaseName"], render: renderOrderUsecaseCell },
    { key: "current_state_name", name: intl.messages["orders.table.head.state"] },
  ], [account, renderCACell, config, intl]);  

  useEffect(() => {
    if (!dayBegin || !dayEnd || !usecases) {
      return;
    }

    const getCampaignName = (row) => {
      if (row.usecase === "newsletter") {
        return account.mjmlTemplates.find(template => template.id === row.newsletter_id)?.name || "";
      }
      return `${intl.messages[`usecase.${row.usecaseName}`]}${Number.isInteger(+row.usecase.substr(-1)) ? ` ${row.usecase.substr(-1)}` : ""}`;
    };

    auth.fetch(`/data/getCampaignsStatistics?usecases=${usecases}&startDate=${dayBegin}&endDate=${dayEnd}`).then(result => setCampaignStats(result.map(row => {
      const category = usecasesList.find(({ name }) => name === row.usecaseName)?.category;

      return {
        name: getCampaignName(row),
        category,
        sentDate: ["newsletter", "events"].includes(category) ? new Date(row.date_email_send) : null,
        emails: row.send,
        open: row.open,
        click: row.click,
        unsubscribe: row.unsubscribe,
        reactivity: row.reactivity,
        hardbounce: row.hardbounce,
      };
    })));

    setUsecasesOrderDataIsLoading(true);
    auth.fetch(`/data/getFilteredOrderStatsGroupByUsecase?usecases=${usecases}&startDate=${dayBegin}&endDate=${dayEnd}`)
      .then(data => {
        const formattedData = data.map(row => {
          return {
            ...row,
            category: usecasesList.find(({ name }) => name === row.usecaseName)?.category
          };
        });
        setUsecasesOrderData(formattedData);
        setUsecasesOrderDataIsLoading(false);
      });
  }, [auth, dayBegin, dayEnd, usecases, account, usecasesList, intl, forceReload]);

  const getCampaignName = useCallback((row) => {
    if (row.usecase === "newsletter") {
      return account.mjmlTemplates.find(template => template.id === row.newsletter_id)?.name || "";
    }
    return intl.messages[`templateName.${row.usecase}`];
  }, [account, intl]);

  const fetchOrders = useCallback((reset = false) => {
    setIsOrderLoading(true);    

    const page = reset ? 0 : (orders?.length || 0) / ORDERS_LIMIT;

    auth.fetch(`/data/getPaginatedOrders?limit=${ORDERS_LIMIT}&page=${page}&startDate=${dayBegin}&endDate=${dayEnd}&usecases=${usecases}`).then(result => {
      setOrders(prev => [
        ...(reset ? [] : prev),
        ...result.orders.map(row => ({
          id_order: row.id_order,
          reference: row.reference,
          ca: row.ca,
          email: row.email,
          last_open: new Date(Date.parse(row.last_open)),
          date_order: new Date(Date.parse(row.date_order)),
          usecase: getCampaignName(row),
          category: row.usecase === "newsletter" ? "newsletter" : usecasesList.find(({ name }) => name === row.usecase.slice(0, -1))?.category,
          current_state_name: row.current_state_name ? row.current_state_name.charAt(0).toUpperCase() + row.current_state_name.slice(1) : "-",
          default_customer_group: row.default_customer_group,
        }))
      ]);
      setTotalOrders(result.total);
      setIsOrderLoading(false);
    });
  }, [orders?.length, auth, dayBegin, dayEnd, usecases, getCampaignName, usecasesList]);

  useEffect(() => {
    if (!dayBegin || !dayEnd || !usecases) {
      return;
    }

    if (!orders) {
      fetchOrders(true);
    }
  }, [auth, dayBegin, dayEnd, usecases, intl, fetchOrders, orders]);

  useEffect(() => {
    setOrders(null);
  }, [forceReload, dayBegin, dayEnd, usecases]);

  const { columns, rows, total, loadMore, isLoading, defaultOrder } = useMemo(() => selectedTab === "campaigns" ?
    { columns: campaignsColumns, rows: campaignsStats, total: campaignsStats?.length || 0, loadMore: () => { }, isLoading: false, defaultOrder: { key: "sentDate", type: "desc" } } :
    { columns: orderColumns, rows: orders, total: totalOrders, loadMore: fetchOrders, isLoading: isOrderLoading },
  [selectedTab, campaignsColumns, campaignsStats, orders, orderColumns, totalOrders, fetchOrders, isOrderLoading]);

  useEffect(() => {
    //reset array on filter change
    setTableKey(prev => prev + 1 );
  }, [selectedTab, dayBegin, dayEnd, usecases]);

  const onCsvExport = async () => {
    if (!rows.length) {
      return;
    }

    if (selectedTab === "campaigns") {
      const formattedCampaigns = campaignsStats.map(row => {
        return columns.map(col => {

          if (col.key === "sentDate") {
            return row.sentDate ? moment(row.sentDate).format("DD/MM/YYYY") : "--";
          }

          if (col.key === "category") {
            return intl.messages[`dashboard.campaigns.type.${row.category}`];
          }

          return row[col.key];
        });
      });


      
      const header = columns.map(column => column.name);
      const csv = "data:text/csv;charset=utf-8," + convertArrayToCSV(formattedCampaigns, { header, separator: ";" });
      const downloadData = encodeURI(csv.replace(/&|#/g, ""));
      const link = document.createElement("a");
      link.setAttribute("href", downloadData);
      link.setAttribute("download", "campaigns_export.csv");
      link.click();
    } else {
      try {
        const header = columns.map(column => ({
          id: column.key,
          title: column.name
        }));

        const usecaseWordings = {};
        for (const message in intl.messages) {
          if (message.startsWith("templateName.")) {
            usecaseWordings[message.replace("templateName.", "")] = intl.messages[message];
          }
        }

        const sendExportViaEmail = total >= 10_000;

        const response = await auth.fetch("/api/data/exportOrdersCSV", {
          method: "POST",
          body: JSON.stringify({
            header,
            usecases,
            startDate: dayBegin.toISOString(),
            endDate: dayEnd.toISOString(),
            usecaseWordings: usecaseWordings,
            sendViaEmail: sendExportViaEmail,
            locale: intl.locale
          }),
        }, {
          rawResponse: true
        });

        if (sendExportViaEmail) {
          const user = await auth.getUser();
          EventSystem.newNotification(
            "notification.action",
            intl.formatMessage({ id: "csv.export.sendViaEmail" }, { email: user.attributes.email })
          );
        } else {
          const blob = await response.blob();
          const fileUrl = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = fileUrl;
          link.download = "orders_export.csv";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      } catch (error) {
        console.error(error);
        EventSystem.newNotification("notification.action", intl.messages["error.main_error"]);
      }
    }
  };

  const emptyComponent = (
    <div className={classes.emptyState}>
      <img src={noDataBackground} alt="" height={200} />
      <div className="text"><Translation id="table.noData" /></div>
    </div>
  );

  const onChartTypeChange = (checked) => {
    setChartType(checked ? "ca" : "nb_order");
  };

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <div className={classes.tableTabs}>
          <div className={`${classes.tableTab} ${selectedTab === "campaigns" ? classes.selectedTab : ""}`} onClick={() => setSelectedTab("campaigns")}>
            <h4><Translation id="dashboard.tables.campaign" /></h4>
            <div className={classes.underline} />
          </div>
          <div className={`${classes.tableTab} ${selectedTab === "orders" ? classes.selectedTab : ""}`} onClick={() => setSelectedTab("orders")}>
            <h4><Translation id="dashboard.tables.orders" /></h4>
            <div className={classes.underline} />
          </div>
        </div>

        <div className={classes.buttonsContainer}>
          {/* <CustomButton
            size="xs"
            type="tertiary"
          >
            <i className="fa-solid fa-file-arrow-down" />
            <Translation id="dashboard.customizedReport" />
          </CustomButton> */}
          <CustomButton
            size="xs"
            type="tertiary"
            onClick={onCsvExport}
          >
            <i className="fa-solid fa-file-arrow-down" />
            <Translation id="dashboard.csvExport" />
          </CustomButton>
        </div>
      </div>

      <div className={classes.tableContainer}>
        {rows &&
          <>
            <Table
              key={tableKey}
              columns={columns}
              rows={rows}
              total={total}
              loadMore={loadMore}
              isLoading={isLoading}
              defaultOrder={defaultOrder}
              emptyComponent={emptyComponent}
            />
          </>
        }
      </div>
      { selectedTab === "orders" ?
        <>
          <div className={classes.graphTitle}>
            <Translation id="dashboard.graph.title" />
          </div>
          <div className={classNames(classes.graphContainer, classes.boxShadow)}>

            <div className={classes.switchWrapper}>
              <Translation id="dashboard.filters.chartType.orders" />
              <Switch checked={chartType === "ca"} onChange={event => onChartTypeChange(event.target.checked)} />
              <Translation id="dashboard.filters.chartType.ca" /> (€)
            </div>
            <DashboardGraphs data={usecasesOrderData} loading={usecasesOrderDataIsLoading} dataType={chartType} usecasesList={usecasesList}></DashboardGraphs>
          </div></> : null }
        
    </div>

  );
};
