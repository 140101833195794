const LABEL = "paragraph4";
const CATEGORY = "text:paragraph";

export const paragraph4 = (variables) => ({
  label: LABEL,
  category: CATEGORY,
  block: (overrides) => {

    const { textFontProps= {},  buttonFontProps = {}, text, backgroundColor, buttonColor, buttonText, buttonHref = "", productIds = [] } = {
      ...variables,
      ...(overrides ? overrides : {}),
    };

    const mjml = `
      <mj-wrapper data-gjs="kilibaBlock" background-color="${backgroundColor}" padding-top="0" padding-bottom="0" full-width="full-width" data-text-product-ids="${productIds.join(",")}">
        <mj-section>
          <mj-column>
            <mj-text text-decoration="${textFontProps.textDecoration}" font-style="${textFontProps.fontStyle}" font-weight="${textFontProps.fontWeight}" color="${textFontProps.color}" font-family="${textFontProps.fontFamily}" line-height="1.3" padding-bottom="15px" font-size="14px" align="left" data-text-id="text">
              {{content}}
            </mj-text>
            <mj-button href="${buttonHref}" text-decoration="${buttonFontProps.textDecoration}" font-style="${buttonFontProps.fontStyle}" font-weight="${buttonFontProps.fontWeight}" font-family="${buttonFontProps.fontFamily}" line-height="1" color="${buttonFontProps.color}" font-size="10px" padding-top="0px" padding-bottom="0px" inner-padding="8px 15px" align="left" background-color="${buttonColor}">
              {{button}}
            </mj-button>
          </mj-column>
        </mj-section>
      </mj-wrapper>
    `;

    const wordings = {
      content: text,
      button: buttonText || {
        fr: "En savoir +",
        en: "Read more",
        es: "Más información",
        de: "Mehr erfahren",
        nl: "Lees meer",
        it: "Per saperne di più",
        pt: "Saiba mais",
      },
    };

    return {
      id: LABEL,
      label: LABEL,
      category: CATEGORY,
      activate: true,
      content: mjml,
      attributes: {
        wordings,
        editableProperties: [
          { key: "texts", type: "generatedTexts", label: "", group: "newsletter.rightMenu.propertyGroup.generatedTexts" },
          { key: "buttonHref", type: "link", label: "newsletter.rightMenu.property.buttonLink", group: "newsletter.rightMenu.propertyGroup.button" },
          { key: "buttonColor", type: "color", label: "newsletter.rightMenu.property.buttonColor", group: "newsletter.rightMenu.propertyGroup.button" },
          { key: "backgroundColor", type: "color", label: "newsletter.rightMenu.property.backgroundColor", group: "newsletter.rightMenu.propertyGroup.block" },
        ]
      }
    };
  }
});