import { palette } from "../../../styles/palette";

const cellHeight = 41;
const tableHeight = cellHeight * 11;


export const styles = theme => ({
  tableWrapper: {
    fontFamily: "Poppins",
    position: "relative",
    padding: "15px 15px 0 15px",
    minHeight: props => props.emptyStateVariant ? 0 : tableHeight + (props.horizontalScrollBarOffset || 0),
    "&::before": {
      content: "\"\"",
      height: cellHeight,
      backgroundColor: palette.neutralUltraLightGrey,
      position: "absolute",
      top: 15,
      left: 15,
      right: 15,
      borderRadius: 8,
    },
    "&::after": {
      content: "\"\"",
      height: cellHeight,
    }
  },
  tableWrapperWithoutPinkBand: {
    fontFamily: "Poppins",
    position: "relative",
    padding: "15px 15px 0 15px",
    minHeight: props => props.emptyStateVariant ? 0 : tableHeight + (props.horizontalScrollBarOffset || 0)
  },
  table: {
    fontSize: 12,
    borderRadius: 8,
    display: "grid",
    gridAutoFlow: "row",
    overflowX: "auto",
    position: "relative",
    gridTemplateRows: "repeat(10, max-content)",
    height: props => props.noCms || props.emptyStateVariant || !props.rows ? "" : "100%",
    minHeight: "inherit"
  },
  columnContentContainer: {
    display: "flex",
    alignItems: "center",
  },
  cell: {
    height: cellHeight,
    padding: "0 20px",
    display: "flex",
    justifyContent: "left",
    alignItems: "center",
    minWidth: 0,
    maxWidth:"600px",
    overflow:"hidden",
    whiteSpace: "nowrap",
  },
  categoriesCell: {
    height: cellHeight,
    padding: "0 20px",
    display: "flex",
    justifyContent: "left",
    alignItems: "center",
    minWidth: "400px",
    maxWidth:"400px",
    overflow:"hidden",
    whiteSpace: "nowrap"
  },
  cellContent: {
    display: "flex",
    justifyContent: "left",
    alignItems: "center",
    minWidth: 0
  },
  cellInnerContent: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap"
  },
  headCell: {
    color: palette.neutralDarkGrey,
    cursor: "pointer",
    "& p": {
      fontSize: 13,
      fontWeight: 500,
    }
  },
  headCellWithoutPointer: {
    color: palette.neutralDarkGrey,
    "& p": {
      fontSize: 13,
      fontWeight: 500,
    }
  },
  withBorder: {
    borderTop: `1px solid ${palette.neutralLightGrey}`,
  },
  selectColumn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  email: {
    width: "13vw",
  },
  fullName: {
    width: "10vw",
  },
  gender: {
    width: "4vw",
  },
  languageId: {
    width: "4vw",
  },
  statusReason: {
    width: "10vw",
  },
  

  sortIcon: {
    fontWeight: 400,
    marginLeft: 8,
    fontSize: 12,
  },

  separator: {
    height: 1,
    backgroundColor: palette.neutralBCGGrey,
    margin: "0 15px",
  },

  emptyStateContainer: {
    height: props => props.noCms || props.emptyStateVariant || !props.rows ? cellHeight * 10 : "",
  },

  rowCursor: {
    cursor: "pointer"
  },

  maxTdSize: {
    maxWidth: "200px"
  },

  row: {
    display: "contents",
  },
  pointer: {
    cursor: "pointer",
  }
});
