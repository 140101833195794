import { subtitle } from "../texts/subtitle";
import { product1 } from "../products/product1";
import { paragraph7 } from "../texts/paragraph7";
import { globalTrustpilot2 } from "../review/globalTrustpilot2";
import { globalAvisverifies1 } from "../review/globalAvisverifies1";
import { makeTranslatable } from "../utils";

const LABEL = "body2";

export const body2 = ({ fontFamily, textColor, titleFontProps = {}, subtitleFontProps = {}, accentColor, generatedWordings, dominantColor, product, buttonTextColor, trustPilotReviews, avisVerifiesReviews, currency, priceIsVisible, currencyBefore }) => ({
  label: LABEL,
  block: () => {

    let productHref = product.absolute_url;
    if (!productHref?.startsWith("http")) {
      productHref = `https://${productHref}`;
    }

    const subtitleBlock = subtitle({ titleFontProps, subtitleFontProps, accentColor, backgroundColor: dominantColor, title: generatedWordings.title, subtitle: generatedWordings.subtitle }).block();
    const productBlock = product1({ product, fontFamily, buttonTextColor, buttonColor: accentColor, textColor, backgroundColor: dominantColor, imageUrl: makeTranslatable(product.image_url), imageHref: makeTranslatable(productHref), imageAlt: makeTranslatable(""), buttonHref: makeTranslatable(productHref), currency, priceIsVisible, currencyBefore }).block();
    const paragraphBlock = paragraph7({ titleFontProps, subtitleFontProps, text: generatedWordings.text, backgroundColor: dominantColor }).block();

    let reviewMjml = "";

    if (trustPilotReviews?.reviews?.length && trustPilotReviews?.globalRating >= 4 && (!avisVerifiesReviews?.reviews.length || trustPilotReviews?.globalRating >= avisVerifiesReviews?.globalRating )) {
      const trustpilotBlock = globalTrustpilot2({ rating: trustPilotReviews.globalRating, fontFamily, textColor, dominantColor }).block();
      reviewMjml = trustpilotBlock.content.replaceAll("data-gjs=\"kilibaBlock\"", `data-gjs="kilibaBlock" data-blocklabel="${trustpilotBlock.label}"`);
    } else if (avisVerifiesReviews?.reviews?.length && avisVerifiesReviews?.globalRating >= 4 && (!trustPilotReviews?.reviews.length || avisVerifiesReviews?.globalRating > trustPilotReviews?.globalRating )) {
      const avisverifiesBlock = globalAvisverifies1({ rating: avisVerifiesReviews.globalRating, fontFamily, textColor, dominantColor }).block();
      reviewMjml = avisverifiesBlock.content.replaceAll("data-gjs=\"kilibaBlock\"", `data-gjs="kilibaBlock" data-blocklabel="${avisverifiesBlock.label}"`);
    }

    const mjml = `
      ${subtitleBlock.content.replaceAll("data-gjs=\"kilibaBlock\"", `data-gjs="kilibaBlock" data-blocklabel="${subtitleBlock.label}"`)}
      ${paragraphBlock.content.replaceAll("data-gjs=\"kilibaBlock\"", `data-gjs="kilibaBlock" data-blocklabel="${paragraphBlock.label}"`)}
      ${productBlock.content.replaceAll("data-gjs=\"kilibaBlock\"", `data-gjs="kilibaBlock" data-blocklabel="${productBlock.label}"`)}
      ${reviewMjml}
    `;

    return {
      id: LABEL,
      label: LABEL,
      category: "body:subBody",
      activate: true,
      content: mjml,
      attributes: {
        wordings: {
          ...subtitleBlock.attributes.wordings,
          ...paragraphBlock.attributes.wordings,
          ...productBlock.attributes.wordings,
        },
        variables: productBlock.attributes.variables
      }
    };
  },
});