
import { useState, useEffect } from "react";
import classes from "./TextDecorationInput.module.scss";

export const TextDecorationInput = ({ isBold, isItalic, isUnderline, handleTextDecorationsChange }) => {

  const [textDecorationsTab, setTextDecorationsTab] = useState([
    { name: "bold", value: false },
    { name: "italic", value: false },
    { name: "underline", value: false },
  ]);

  const handleChange = (name) => {
    const updatedTextDecorationsTab = textDecorationsTab.map(style => 
      style.name === name ? { ...style, value: !style.value } : style
    );
    
    setTextDecorationsTab(updatedTextDecorationsTab);
    handleTextDecorationsChange(updatedTextDecorationsTab);
  };

  useEffect(() => {
    const updatedTextDecorationsTab = textDecorationsTab.map(style => {
      if (style.name === "bold") { return { ...style, value: isBold };}
      if (style.name === "italic") { return { ...style, value: isItalic };}
      if (style.name === "underline") { return { ...style, value: isUnderline }; }
      return style;
    });
    setTextDecorationsTab(updatedTextDecorationsTab);
  }, [isBold, isItalic, isUnderline]);

  return (
    <div>
      <div className={classes.textAlignInput}>
        {textDecorationsTab.map((style) => (
          <div
            key={style.name}
            onClick={() => handleChange(style.name)}
            className={style.value ? classes.alignSelected : ""}
          >
            <i className={`fa-regular fa-${style.name}`}></i>
          </div>
        ))}
      </div>
    </div>
  );
};

