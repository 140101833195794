import { useCallback, useEffect, useMemo, useState } from "react";
import { injectIntl } from "react-intl";
import { makeStyles } from "@material-ui/core";
import moment from "moment-timezone";
import { EventSystem } from "../../../../eventsystem/EventSystem.js";
import { Translation } from "../../../common/Translation";

import { Dialog } from "../../../common/Dialog";
import { RadioGroup } from "../../../common/RadioGroup";
import { Radio } from "../../../common/Input/Radio/Radio";
import { TextField } from "../../../common/Input/TextField";
import { ItemPicker } from "../../../pickers/ItemPicker";
import { KiDatePicker } from "../../../common/KiDatePicker/KiDatePicker";
import { FormButtonTO } from "../../../FormButtonTO";

import { useAuth } from "../../../../hooks/useAuth";

import { palette } from "../../../../styles/palette";
import { getMidnightDate } from "../../../../tools/date";
import { usecasesChronologyConfig } from "../../../../ressources/usecasesChronologyConfig";

const styles = theme => ({
  container: {
    maxWidth: 500,
  },
  title: {
    fontFamily: "Coolvetica",
    lineHeight: "25px",
    marginBottom: 25,
    fontSize: 22,
    textAlign: "center",
  },
  subtitle: {
    // padding: "0px 35px",
    lineHeight: "18px",
    fontFamily: "Poppins",
    textAlign: "left",
    fontSize: 14,
  },
  form: {
    paddingTop: 40,
    textAlign: "start",
    fontFamily: "Poppins",
    fontSize: 14,
  },
  formTitle: {
    fontFamily: "Coolvetica Book",
    lineHeight: "25px",
    marginBottom: 25,
    fontSize: 16,
    fontWeight: 400,
    letterSpacing: 0.5,
  },
  dateOptionContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: 15,
  },
  dateOption: {
    display: "flex",
    alignItems: "center",
    gridGap: 10,
    marginLeft: -10,    
  },
  dateOptionDynamic: {
    "& .MuiTextField-root": {
      width: 40,
      "& input": {
        width: 40,
        textAlign: "center",
        padding: 0,
        minWidth: "inherit !important"
      }
    },
    "& button": {
      color: palette.primaryBlack,
    }
  },
  dateOptionFixed: {
    "& .MuiTextField-root": {
      width: 100,
      "& .MuiInputBase-root": {
        background: palette.neutralInputs,
      },
      "& input": {
        background: "transparent",
        width: 100,
        textAlign: "center",
        padding: 0,
        minWidth: "inherit !important"
      }
    },
  },
  buttonsContainer: {
    marginTop: 50,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gridGap: 15,
  },
  buttonReset: {
    borderBottom: "1px solid " + palette.primaryBlack,
    lineHeight: 0.9,
    cursor: "pointer",
    fontSize: 12,
  },
  onlyAfterText: {
    marginLeft: -10,
  },
  dateError: {
    color:"red",
    position: "absolute",
    marginTop: "-10px",
    fontSize: 12,
  }
});

const useStyles = makeStyles(styles);

const chronologyConfigType = {
  DYNAMIC_DATE: "1",
  DDAY: "2",
  FIXED_DATE: "3"
};

export const UsecaseConfigPopup = injectIntl(({
  isOpen,
  close,
  intl,
  usecaseName,
  templateDataKey,
  usecaseConfig,
  usecase,
  setUsecaseConfig
}) => {
  const classes = useStyles();
  const auth = useAuth();

  const [dateSelectedOption, setDateSelectedOption] = useState(chronologyConfigType.DYNAMIC_DATE);
  const [nbDays, setNbDays] = useState(1);
  const [errorDate, setErrorDate] = useState(false);
  const [selectedItem, setSelectedItem] = useState("before");
  
  const [date, setDate] = useState(() => {
    const defaultDate = new Date();
    defaultDate.setDate(defaultDate.getDate() + 1);
    return defaultDate;
  });

  const referenceDate = useMemo(() => {
    const defaultConfig = usecase.templateDatas[templateDataKey].meta;
    return defaultConfig.date_reference ? new Date(defaultConfig.date_reference) : null;
  }, [usecase, templateDataKey]);

  const cleanDate = (date) => {
    const finalDate = getMidnightDate(date);
    return new Date(finalDate.getTime() - finalDate.getTimezoneOffset() * 60000);
  };

  const reset = useCallback((force = false) => {
    if (!usecaseConfig) {
      return ;
    }
    const config = usecaseConfig[templateDataKey];

    //Reset config
    if (!config?.type || force) {
      const defaultConfig = usecase.templateDatas[templateDataKey].meta;

      if (defaultConfig.scheduling_type === "fixedDate") {
        const referenceDate = new Date(defaultConfig.date_reference);
        const dateSent = new Date(referenceDate.getFullYear(), defaultConfig.scheduling_date_month - 1, defaultConfig.scheduling_date_dayofmonth);

        referenceDate.setHours(0,0,0,0);
        dateSent.setHours(0,0,0,0);

        //If fixed date === sent date, set type to DDAY, else DYNAMIC_DATE
        if (referenceDate.getTime() === dateSent.getTime()) {
          setDateSelectedOption(chronologyConfigType.DDAY);
        } else {
          const diffDays = Math.floor((dateSent.getTime() - referenceDate.getTime()) / (1000 * 3600 * 24));
          setDateSelectedOption(chronologyConfigType.DYNAMIC_DATE);
          setSelectedItem(diffDays < 0 ? "before" : "after");
          setNbDays(Math.abs(diffDays));
        }
      } else if (defaultConfig.scheduling_type === "daily") {
        //for daily usecase, if default value is in config, type is DYNAMIC_DATE, else DDAY
        if (usecasesChronologyConfig[usecaseName].defaultValue && usecasesChronologyConfig[usecaseName].defaultValue[templateDataKey]) {
          setDateSelectedOption(chronologyConfigType.DYNAMIC_DATE);
          setSelectedItem(usecasesChronologyConfig[usecaseName].defaultValue[templateDataKey] < 0 ? "before" : "after");
          setNbDays(Math.abs(usecasesChronologyConfig[usecaseName].defaultValue[templateDataKey]));
        } else {
          setDateSelectedOption(chronologyConfigType.DDAY);
        }
      }

    //Use saved config
    } else if (config.type === "fixedDate") {
      if (new Date(config.value).getTime() === cleanDate(usecase.date).getTime()) {
        setDateSelectedOption(chronologyConfigType.DDAY);
      } else {
        setDateSelectedOption(chronologyConfigType.FIXED_DATE);
        setDate(config.value);
      }
    } else if (config.type === "dynamicDate") {
      setDateSelectedOption(chronologyConfigType.DYNAMIC_DATE);
      setSelectedItem(config.value < 0 ? "before" : "after");
      setNbDays(Math.abs(config.value));
    } else if (config.type === "d-day") {
      setDateSelectedOption(chronologyConfigType.DDAY);
    }
  }, [usecaseConfig, templateDataKey, usecase, usecaseName]);

  useEffect(() => {
    reset();
  }, [reset, isOpen]);



  const handleDateChange = (e) => {
    const selected = new Date(e);
    const tomorrow = new Date();
    selected.setHours(selected.getHours() + 1);
    tomorrow.setDate(tomorrow.getDate() + 1);
    const error = selected < tomorrow;
    setDate(error ? tomorrow : selected);
    setErrorDate(error);
  };


  const _setNbDays = (value) => {
    if(value != "0") {
      if (Number.isInteger(+value) && +value >= 0) {
        setNbDays(value);
      }
    } else {
      setNbDays(1);
    }
  };

  const onSubmit = () => {
    const config = usecaseConfig || [];

    for (let i = 0; i <= templateDataKey; i++) {
      if (config.length === i) {
        config.push({});
      }
    }

    let type, value;

    switch(dateSelectedOption) {
      case chronologyConfigType.DYNAMIC_DATE: 
        type = "dynamicDate";
        value = selectedItem === "before" && usecasesChronologyConfig[usecaseName].option1 !== "onlyAfter" ? -nbDays : nbDays;
        break;

      case chronologyConfigType.DDAY:
        type = "d-day";
        break ;

      case chronologyConfigType.FIXED_DATE:
        type = "fixedDate";
        value = cleanDate(date);
        break;

      default: break;
    }

    config[templateDataKey] = { type, value };

    auth.fetch("/api/account_management/setUsecaseConfig", {
      method: "POST",
      body: JSON.stringify({ usecaseName, config }),
    }).then(() => {
      EventSystem.newNotification("notification.action", <Translation id="usecase.configPopup.save.confirm" />);
      setUsecaseConfig(config);
      close();
    });
  };

  return (
    <Dialog
      PaperProps={{ style: { borderRadius: 20 } }}
      isOpen={isOpen}
      close={close}
      padding="64px 64px 40px 64px"
    >
      <div className={classes.container}>
        <h2 className={classes.title}>{intl.messages["usecaseConfigPopup.title"]}</h2>
        <span className={classes.subtitle}>{intl.messages["usecaseConfigPopup.subtitle"]}</span>
        <div className={classes.form}>
          <h3 className={classes.formTitle}>{intl.messages["usecaseConfigPopup.form.date.title"]}</h3>
          <RadioGroup 
            value={dateSelectedOption} 
            onChange={event => setDateSelectedOption(event.target.value)}
          >
            
            <div className={classes.dateOptionContainer}>
              <Radio value={chronologyConfigType.DYNAMIC_DATE} />
              <div className={`${classes.dateOption} ${classes.dateOptionDynamic}`}>
                <TextField 
                  value={nbDays}
                  onChange={event => {
                    _setNbDays(event.target.value);
                    setDateSelectedOption(chronologyConfigType.DYNAMIC_DATE);
                  }}
                />
                <span>{intl.messages["usecaseConfigPopup.form.date.option1"]}</span>
                {usecasesChronologyConfig[usecaseName].option1 !== "onlyAfter" ?
                  <ItemPicker 
                    alternativeStyle
                    listName={usecasesChronologyConfig[usecaseName].listName || "usecasesChronology"}
                    onSelect={value => {
                      setSelectedItem(value);
                      setDateSelectedOption(chronologyConfigType.DYNAMIC_DATE);
                    }}
                    selectedItem={selectedItem}
                    titleKey={selectedItem}
                    style={{ width: 215 }}
                    width={200}
                  /> :
                  <span className={classes.onlyAfterText}>
                    &nbsp;{intl.messages[usecasesChronologyConfig[usecaseName].optionText1]?.toLowerCase()}
                  </span>
                }
              </div>
            </div>
            {usecasesChronologyConfig[usecaseName].option2 !== false && 
              <div className={classes.dateOptionContainer}>
                <Radio value={chronologyConfigType.DDAY} />
                <div className={classes.dateOption}>
                  { intl.messages[usecasesChronologyConfig[usecaseName].optionText2] ? intl.messages[usecasesChronologyConfig[usecaseName].optionText2] : intl.messages["usecaseConfigPopup.form.date.option2"]}
                  {referenceDate && ` ( ${moment(referenceDate).format("DD/MM/YYYY")} )`}
                </div>
              </div>
            }
            {
              usecasesChronologyConfig[usecaseName].option3 !== false && 
              <div className={classes.dateOptionContainer}>
                <Radio value={chronologyConfigType.FIXED_DATE} />
                <div className={`${classes.dateOption} ${classes.dateOptionFixed}`}>
                  <span>{intl.messages["usecaseConfigPopup.form.date.option3"]}</span>
                  <KiDatePicker
                    disablePast
                    disableToday
                    value={date}
                    onChange={value => {
                      handleDateChange(value);
                      setDateSelectedOption(chronologyConfigType.FIXED_DATE);
                    }}
                  />
                </div>
              </div>
            }
          </RadioGroup>
          {errorDate && <span className={classes.dateError}> {intl.messages["usecase.configPopup.errorDate"]} </span>}



          <div className={classes.buttonsContainer}>
            <FormButtonTO 
              label={intl.messages["usecaseConfigPopup.form.button.save"]}
              action={onSubmit}
            />
            <small
              className={classes.buttonReset}
              onClick={() => reset(true)}
            >
              {intl.messages["usecaseConfigPopup.form.button.reset"]}
            </small>
          </div>
        </div>
      </div>
    </Dialog>
  );
});
