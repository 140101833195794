import { subtitle } from "../texts/subtitle";
import { product1 } from "../products/product1";
import { lineSeparator } from "../components/separators/lineSeparator";
import { trustpilot3 } from "../review/trustpilot3";
import { avisverifies4 } from "../review/avisverifies4";
import { makeTranslatable } from "../utils";

const LABEL = "body1";

export const body1 = ({ fontFamily, textColor, titleFontProps = {}, subtitleFontProps = {}, accentColor, generatedWordings, dominantColor, product, buttonTextColor, trustPilotReviews, avisVerifiesReviews, isDominantColorLight, currency, priceIsVisible, currencyBefore }) => ({
  label: LABEL,
  block: () => {

    let productHref = product.absolute_url;
    if (!productHref?.startsWith("http")) {
      productHref = `https://${productHref}`;
    }

    const subtitleBlock = subtitle({ titleFontProps, subtitleFontProps, accentColor, backgroundColor: dominantColor, title: generatedWordings.title, subtitle: generatedWordings.subtitle }).block();
    const productBlock = product1({ product, fontFamily, buttonTextColor, buttonColor: accentColor, textColor, backgroundColor: dominantColor, imageUrl: makeTranslatable(product.image_url), imageHref: makeTranslatable(productHref), imageAlt: makeTranslatable(""), buttonHref: makeTranslatable(productHref), currency, priceIsVisible, currencyBefore }).block();
    const separatorBlock = lineSeparator({ dominantColor, isDominantColorLight }).block();

    let reviewMjml = "";
    
    if (trustPilotReviews?.reviews?.length && trustPilotReviews?.reviews[0].rating >= 4 && (!avisVerifiesReviews?.reviews.length || trustPilotReviews?.reviews[0].rating >= avisVerifiesReviews?.reviews[0].rating)) {
      const review = trustPilotReviews.reviews[0];
      const trustpilotBlock = trustpilot3({ review, fontFamily, textColor, buttonTextColor, accentColor, dominantColor }).block();
      reviewMjml = `
        ${separatorBlock.content.replaceAll("data-gjs=\"kilibaBlock\"", `data-gjs="kilibaBlock" data-blocklabel="${separatorBlock.label}"`)}
        ${trustpilotBlock.content.replaceAll("data-gjs=\"kilibaBlock\"", `data-gjs="kilibaBlock" data-blocklabel="${trustpilotBlock.label}"`)}
      `;
    } else if (avisVerifiesReviews?.reviews?.length && avisVerifiesReviews?.reviews[0].rating >= 4 && (!trustPilotReviews?.reviews.length || avisVerifiesReviews?.reviews[0].rating >= trustPilotReviews?.reviews[0].rating)) {
      const review = avisVerifiesReviews.reviews[0];
      const avisverifiesBlock = avisverifies4({ review, fontFamily, textColor, buttonTextColor, accentColor, dominantColor }).block();
      reviewMjml = `
        ${separatorBlock.content.replaceAll("data-gjs=\"kilibaBlock\"", `data-gjs="kilibaBlock" data-blocklabel="${separatorBlock.label}"`)}
        ${avisverifiesBlock.content.replaceAll("data-gjs=\"kilibaBlock\"", `data-gjs="kilibaBlock" data-blocklabel="${avisverifiesBlock.label}"`)}
      `;
    }

    const mjml = `
      ${subtitleBlock.content.replaceAll("data-gjs=\"kilibaBlock\"", `data-gjs="kilibaBlock" data-blocklabel="${subtitleBlock.label}"`)}
      ${productBlock.content.replaceAll("data-gjs=\"kilibaBlock\"", `data-gjs="kilibaBlock" data-blocklabel="${productBlock.label}"`)}
      ${reviewMjml}
    `;

    return {
      id: LABEL,
      label: LABEL,
      category: "body:subBody",
      activate: true,
      content: mjml,
      attributes: {
        wordings: {
          ...subtitleBlock.attributes.wordings,
          ...productBlock.attributes.wordings,
        },
        variables: productBlock.attributes.variables
      }
    };
  },
});