import Tooltip from "@mui/material/Tooltip";
import { useState, useMemo, useEffect, useCallback, useContext, useRef } from "react";
import { useIntl } from "react-intl";
import { SketchPicker } from "react-color";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import DraggableList from "react-draggable-list";
import { cloneDeep } from "lodash";

import { CustomButton } from "../../common/Button/CustomButton";
import { Translation } from "../../common/Translation";
import { Switch } from "../../common/Input/Switch";
import { TextField } from "../../common/Input/TextField";
import { ImageOptionalMenu } from "../grapesJsKiliba/optionalMenu/imageMenu";
import { GeneratedTextProposal, GeneratedTextMenu } from "./GeneratedTextMenu.jsx";
import { AccountContext } from "../../../contexts/context.js";
import { TemplateEditorContext } from "../TemplateEditorContext";
import { useAuth } from "../../../hooks/useAuth";
import { PromoCodeOptionalMenu } from "./../grapesJsKiliba/optionalMenu/promoCodeMenu";
import { makeTranslatable } from "../grapesJsKiliba/blocks/utils.js";
import { ImageSizeInput } from "./RightMenuEditContentInputs/ImageSizeInput.jsx";
import { ImageAlignInput } from "./RightMenuEditContentInputs/ImageAlignInput.jsx";
import { ImageGaleryMenu, imagePreview } from "./ImageGaleryMenu/ImageGaleryMenu.jsx";
import { monoProductToMultiProducts, multiProductsToMonoProduct } from "../grapesJsKiliba/blocks/products/index.js";
import { ColumnsInput } from "./RightMenuEditContentInputs/ColumnsInput.jsx";
import { CropMenu } from "./CropMenu.jsx";
import { EventSystem } from "../../../eventsystem/EventSystem";
import { NewsletterChooseProductInput } from "../../Newsletter/NewsletterChooseProductInput.jsx";
import { getContentFromProduct } from "./ProductMenu.jsx";
import { RecoProductList } from "./RecoProductMenu.jsx";
import { previewRecoProduct } from "../grapesJsKiliba/blocks/recoProducts/index.js";

import classes from "./RightMenuEditContent.module.scss";

const getUpdatedMultilingualVariable = (locale, language, value, newValue) => {
  if (locale === language) {
    return makeTranslatable(newValue);
  } else {
    return {
      ...value,
      [language]: newValue,
    };
  }
};

const ImagePropertyInput = ({ value, openImageMenu, editor, onChange, openCropMenu }) => {

  const { language } = useContext(TemplateEditorContext);
  const isMultilingual = useMemo(() => typeof value !== "string", [value]);

  const intl = useIntl();
  const onCropEnd = useCallback((props) => {
    if (props.type === "images") {
      onChange(isMultilingual ? getUpdatedMultilingualVariable(intl.locale, language, value, props.url) : props.url);
    }
  }, [onChange, language, value, isMultilingual, intl]);

  useEffect(() => {
    if (editor) {
      editor.on("onCropEnd", onCropEnd);
      return () => editor.off("onCropEnd", onCropEnd);
    }
  }, [editor, onCropEnd]);

  return (
    <div className={classes.imagePropertyInputContainer}>
      <div className={classes.imagePropertyInputImageContainer}>
        <img src={isMultilingual ? value[language] : value} width={175} alt="" />
        {(isMultilingual ? value[language] : value) !== imagePreview &&
          <div className={classes.imagePropertyInputOverlay} onClick={openCropMenu}>
            <i className="fa-solid fa-crop-simple" />
            <h4><Translation id="rightmenu.editContent.image.crop" /></h4>
          </div>
        }
      </div>
      <div className={classes.imagePropertyButtonContainer}>
        <CustomButton
          type="secondary"
          size="xxs"
          onClick={openImageMenu}
        >
          <div className={classes.changeImageButtonTextColor}>
            <i className="fa-solid fa-sparkles" />
            <Translation id="button.changeImage" />
          </div>
        </CustomButton>
      </div>
    </div>
  );
};

const LinkPropertyInput = ({ value, onChange }) => {
  const intl = useIntl();

  const { language } = useContext(TemplateEditorContext);

  const isMultilingual = useMemo(() => typeof value !== "string", [value]);
  const finalValue = useMemo(() => isMultilingual ? value[language] : value, [isMultilingual, value, language]);

  const [_value, setValue] = useState(finalValue);
  const [error, setError] = useState(false);

  const isValidLink = useMemo(() => {
    const regex = new RegExp(/^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/);
    return _value?.match(regex);
  }, [_value]);

  useEffect(() => {
    setValue(finalValue !== "#" ? finalValue : "");
  }, [finalValue]);

  const updateLink = () => {
    onChange(isMultilingual ? getUpdatedMultilingualVariable(intl.locale, language, value, _value) : _value);
  };

  const deleteLink = () => {
    onChange(isMultilingual ? getUpdatedMultilingualVariable(intl.locale, language, value, "#") : "#");
  };

  useEffect(() => {
    if (isValidLink) {
      setError(false);
    }
  }, [isValidLink]);

  return (
    <div className={classes.linkPropertyInputContainer}>
      <div className={classes.propertyInputContainerTextfield}>
        <TextField
          value={_value}
          onChange={event => setValue(event.target.value)}
          placeholder={"https://"}
          errorMessage={error ? intl.messages["error.url.format"] : ""}
          onBlur={() => setError(!isValidLink)}
        />
      </div>
      <div className={classes.linkPropertyInputButtonsContainer}>
        {!finalValue || finalValue === "#" ?
          <CustomButton
            size="xxs"
            onClick={updateLink}
            disabled={!isValidLink || _value === finalValue}
          >
            <Translation id="addLinkPopup.button.insert" />
          </CustomButton> :
          <>
            <CustomButton
              size="xxs"
              type="secondary"
              onClick={deleteLink}
            >
              <Translation id="button.remove" />
            </CustomButton>
            <CustomButton
              size="xxs"
              onClick={updateLink}
              disabled={!isValidLink || _value === finalValue}
            >
              <Translation id="button.link.modify" />
            </CustomButton>
          </>
        }
      </div>
    </div>
  );
};

const StringPropertyInput = ({ value, onChange }) => {

  const intl = useIntl();

  const { language } = useContext(TemplateEditorContext);
  const isMultilingual = useMemo(() => typeof value !== "string", [value]);
  const finalValue = useMemo(() => isMultilingual ? value[language] : value, [isMultilingual, value, language]);
  const [_value, setValue] = useState(finalValue);

  const _setValue = newValue => {
    setValue(newValue.replaceAll("\"", ""));
  };

  useEffect(() => {
    setValue(finalValue);
  }, [finalValue]);

  useEffect(() => {
    if (_value !== finalValue) {
      const timeout = setTimeout(() => {
        onChange(isMultilingual ? getUpdatedMultilingualVariable(intl.locale, language, value, _value) : _value);
      }, 1000);
      return () => clearTimeout(timeout);
    }
  }, [_value, onChange, finalValue, intl, isMultilingual, language, value]);

  return (
    <div>
      <div className={classes.propertyInputContainerTextfield}>
        <TextField
          value={_value}
          onChange={event => _setValue(event.target.value)}
          placeholder={intl.messages["newsletter.rightMenu.property.alt.placeholder"]}
        />
      </div>
    </div>
  );
};

const ImagePropertyInput2 = ({ value, onChange, editor, component, type, templateId, productId, openCropMenu }) => {

  const intl = useIntl();
  const [imageMenuKey, setImageMenuKey] = useState(0);
  const { products, language } = useContext(TemplateEditorContext);

  const isMultilingual = useMemo(() => typeof value !== "string", [value]);

  const onCropEnd = useCallback(props => {
    if (props.type === type) {
      onChange(isMultilingual ? getUpdatedMultilingualVariable(intl.locale, language, value, props.url) : props.url);
      setImageMenuKey(prev => prev + 1);
    }
  }, [onChange, type, isMultilingual, language, value, intl]);

  useEffect(() => {
    setImageMenuKey(prev => prev + 1);
  }, [productId]);

  useEffect(() => {
    if (editor) {
      editor.on("onCropEnd", onCropEnd);
      return () => editor.off("onCropEnd", onCropEnd);
    }
  }, [editor, onCropEnd]);

  return (
    <div>
      <ImageOptionalMenu
        key={imageMenuKey}
        onChange={imageUrl => {
          if (isMultilingual) {
            onChange(getUpdatedMultilingualVariable(intl.locale, language, value, imageUrl));
          } else {
            onChange(imageUrl);
          }
        }}
        selectedUrl={isMultilingual ? value[language] : value}
        allowCrop={!!component.findType("mj-image").length}
        editor={editor}
        component={component.findType("mj-image").length ? component.findType("mj-image")[0] : null}
        type={type}
        onUpload={url => onChange(isMultilingual ? getUpdatedMultilingualVariable(intl.locale, language, value, url) : url)}
        imagesToAdd={products.filter(product => product.id_product === productId).map(({ image_url }) => image_url)}
        templateId={templateId}
        onConfigure={openCropMenu}
      />
    </div>
  );
};

const ColorPropertyInput = ({ value, onChange }) => {

  const { account } = useContext(AccountContext);
  const auth = useAuth();
  const [_value, setValue] = useState(value);
  const [presetColors, setPresetColors] = useState([]);
  const ref = useRef();
  const [position, setPosition] = useState("bottom");

  useEffect(() => {
    if (ref.current.getBoundingClientRect().top < 300) {
      setPosition("top");
    }
  }, []);

  useEffect(() => {
    setValue(value);
  }, [value]);

  useEffect(() => {
    if (_value !== value) {
      const timeout = setTimeout(() => {
        auth.fetch("/account_management/addNewsletterColors", {
          method: "POST",
          body: JSON.stringify({
            colors: [_value],
          })
        });

        if (!presetColors.includes(_value)) {
          setPresetColors(prev => [...prev, _value].slice(-26));
        }
        onChange(_value);
      }, 500);
      return () => clearTimeout(timeout);
    }
  }, [_value, onChange, value, auth, presetColors]);

  useEffect(() => {
    if (account && !presetColors.length) {
      const colors = [];
      const regex = /#?[a-f0-9]{6}/;
      account.template_parameters?.forEach(parameter => {
        if (parameter.value.toLowerCase().match(regex)) {
          colors.push(parameter.value);
        }
      });
      setPresetColors([...new Set(colors), ...(account.newsletterSavedColors || [])]);
    }

  }, [account, presetColors]);

  const [isOpen, setIsOpen] = useState();

  return (
    <div ref={ref} className={classes.colorPropertyInputContainer} onClick={() => setIsOpen(true)}>
      <div className={classes.colorPropertyInputColorRect} style={{ backgroundColor: _value }} />
      <div className="infoSmall">{_value?.toUpperCase()}</div>
      {isOpen &&
        <div className={classes.colorPropertyInputColorPicker} style={{ [position]: "40px" }}>
          <ClickAwayListener onClickAway={() => setIsOpen(false)}>
            <SketchPicker
              color={_value}
              onChange={({ hex }) => setValue(hex)}
              presetColors={presetColors}
            />
          </ClickAwayListener>
        </div>
      }
    </div>
  );
};

const PricePropertyInput = ({ onChange, value }) => {
  return (
    <div className={classes.pricePropertyInputContainer}>
      <div className={classes.pricePropertyInput}>
        <Switch checked={value} onChange={event => onChange(event.target.checked)} />
        <span className="text"><Translation id="newsletter.rightMenu.property.priceIsVisible.text" /></span>
      </div>
    </div>
  );
};

export const GeneratedTextsInput = ({ content, block, language, openTextMenu }) => {

  return (
    <div className={classes.generatedTextsPropertyInputContainer}>
      <GeneratedTextProposal
        wordings={content}
        block={block}
        language={language}
      />
      <CustomButton
        type="secondary"
        size="xxs"
        onClick={openTextMenu}
      >
        <Translation id="button.changeText" />
      </CustomButton>
    </div>
  );
};

const AddRecoInput = ({ content, updateContent, property, inputState, setInputState, component }) => {

  const [disabledPopover, setDisabledPopover] = useState();
  const [showButton, setShowButton] = useState(null);
  const [isOpen, setIsOpen] = useState(inputState?.recoListOpen);
  const { recosInTemplate } = useContext(TemplateEditorContext);

  useEffect(() => {
    setShowButton(null);
  }, [component]);

  useEffect(() => {
    if (showButton === null && (!content.products || content.products.length < 3)) {
      setShowButton(true);
    }
  }, [showButton, content]);

  useEffect(() => {
    if (isOpen) {
      setInputState(prev => ({ ...prev, recoListOpen: true }));
    }
  }, [isOpen, setInputState]);

  useEffect(() => {
    if (disabledPopover) {
      const timeout = setTimeout(() => setDisabledPopover(null), 4000);
      return () => clearTimeout(timeout);
    }
  }, [disabledPopover]);
  
  const onAddReco = (reco) => {
    let newContent = {};

    if (recosInTemplate.length >= 20) {
      return setDisabledPopover("rightMenu.disabledPopover.recoproduct");
    }
    
    if (content.products && content.products.length >= 3) {
      return setDisabledPopover("rightMenuEditContent.addRecoInput.error");
    }

    for (const key of property.keys) {
      newContent[key] = cloneDeep(content[key]);
    }

    if (!content.products) {
      newContent = monoProductToMultiProducts(newContent);
    }


    newContent.products.push(previewRecoProduct);
    newContent.recoTypes.push(reco.name);
    newContent.imagesUrl.push(makeTranslatable(reco.imagePreview));
    newContent.buttonsColor.push(newContent.buttonsColor[newContent.buttonsColor.length - 1]);
    newContent.pricesIsVisible.push(newContent.pricesIsVisible[newContent.pricesIsVisible.length - 1]);
    newContent.imagesHref.push(makeTranslatable(""));
    newContent.imagesAlt.push(makeTranslatable(""));
    newContent.buttonsHref.push(makeTranslatable(""));
    newContent.product = null;
    newContent.productId = null;

    updateContent(newContent);
  };

  return (
    <div className={classes.addRecoInputContainer}>
      {showButton && !isOpen && <div className="link" onClick={() => setIsOpen(true)}><Translation id="rightMenuEditContent.addRecoProductInput.title" /></div>}
      {isOpen &&
        <div className={classes.addRecoInput}>
          <div className={"textMedium"}><Translation id="rightMenuEditContent.addRecoInput.title" /></div>
          <RecoProductList onClick={onAddReco}  />
          {disabledPopover && 
          <div className={`text ${classes.disabledPopover}`}>
            <Translation id={disabledPopover} />
            <i className="fa-solid fa-times" onClick={() => setDisabledPopover(null)}/>
          </div>
          }
        </div>
      }
    </div>
  );
};

const AddProductInput = ({ content, updateContent, property }) => {
  
  const intl = useIntl();
  const [disabledPopover, setDisabledPopover] = useState();

  const { productsInTemplate, addProduct } = useContext(TemplateEditorContext);

  const onAddProduct = (product) => {
    let newContent = {};

    addProduct(product);

    for (const key of property.keys) {
      newContent[key] = cloneDeep(content[key]);
    }

    if (!content.products) {
      newContent = monoProductToMultiProducts(newContent);
    }

    newContent.products.push(product);
    const productContent = getContentFromProduct(product);
    newContent.imagesHref.push(productContent.imageHref);
    newContent.buttonsHref.push(productContent.buttonHref);
    newContent.imagesUrl.push(productContent.imageUrl);
    newContent.imagesAlt.push(makeTranslatable(""));
    newContent.buttonsColor.push(newContent.buttonsColor[newContent.buttonsColor.length - 1]);
    newContent.pricesIsVisible.push(newContent.pricesIsVisible[newContent.pricesIsVisible.length - 1]);
    newContent.product = null;
    newContent.productId = null;

    updateContent(newContent);
  };

  const onInputClick = () => {
    if (content.products && content.products.length >= 3) {      
      setDisabledPopover("rightMenuEditContent.addProductInput.error1");
    }

    if (productsInTemplate?.length >= 6) {
      setDisabledPopover("rightMenuEditContent.addProductInput.error2");
    }
  };

  useEffect(() => {
    if (disabledPopover) {
      const timeout = setTimeout(() => setDisabledPopover(null), 4000);
      return () => clearTimeout(timeout);
    }
  }, [disabledPopover]);

  return (
    <div className={classes.addProductInputContainer}>
      <div className={"textMedium"}><Translation id="rightMenuEditContent.addProductInput.title" /></div>
      <NewsletterChooseProductInput 
        placeholder={intl.messages["productMenu.input.placeholder"]}
        onAddProduct={onAddProduct}
        disabled={(content.products && content.products.length >= 3) || productsInTemplate?.length >= 6}
        onClick={onInputClick}
      />
      {disabledPopover && 
        <div className={`text ${classes.disabledPopover}`} style={{ top: document.getElementById(`category.${disabledPopover}`)?.getBoundingClientRect().top + 30 }}>
          <Translation id={disabledPopover} />
          <i className="fa-solid fa-times" onClick={() => setDisabledPopover(null)}/>
        </div>
      }
    </div>
  );
};

export const ProductInput = ({ content, item, property, setEditMenuInfo, dragHandleProps, itemSelected, updateContent, type = "multi", setDraggableList }) => {
  const intl = useIntl();
  const [time, setTime] = useState(0);

  const { language } = useContext(TemplateEditorContext);

  useEffect(() => {
    if (itemSelected !== 0 && time === 0) {
      setTime(Date.now());
    }
    if (itemSelected === 0) {
      setTime(0);
    }
  }, [itemSelected, time]);

  const onMouseUp = () => {
    if (Date.now() - time < 300) {
      setEditMenuInfo({ type: "subproperties", property, idx: item.idx });
    }
    setTime(0);
  };

  const onClick = () => {
    setEditMenuInfo({ type: "subproperties", property });
  };

  const onTrashMouseDown = (event) => {
    event.stopPropagation();
    let newContent = {};
    for (const key of property.keys) {
      newContent[key] = cloneDeep(content[key]);
      newContent[key].splice(item.idx, 1);
    }

    if (newContent.products.length === 1) {
      newContent = multiProductsToMonoProduct(newContent);
    }
    
    if (setDraggableList) {
      setDraggableList(prev => {
        const value = [...prev];
        value.splice(item.idx, 1);
        return value;
      });
    }

    updateContent(newContent);

    EventSystem.newNotification(
      "notification.action",
      intl.messages["rightMenuEditContent.notif.deleteProduct"]
    );
  };

  const productName = useMemo(() => {
    if (content.recoTypes?.length) {
      return type === "multi" ? intl.messages[`recoProductMenu.reco.${content.recoTypes[item.idx]}`] : intl.messages[`recoProductMenu.reco.${content.recoType}`];
    }
    return type === "multi" ? content.productsName[item.idx][language] : content.productName[language];
  }, [content, item, intl, language, type]);

  return (
    <div {...dragHandleProps} onMouseUp={onMouseUp} onClick={type === "mono" ? onClick : null}>
      <div className={`${classes.productInput} ${itemSelected !== 0 ? classes.productInputDragged : ""}`}>
        <img width={45} alt="" src={type === "multi" ? content.imagesUrl[item.idx][language] : content.imageUrl[language] } />
        <div className={`text ${classes.productTitle}`}>{productName}</div>
        <div className={classes.productInputIcons}>
          {type === "multi" && <i className={`fa-solid fa-trash-can ${classes.productTrash}`} onMouseDown={onTrashMouseDown} />}
          <i className={"fa-solid fa-chevron-right"} />
        </div>
      </div>
    </div>
  );
};

export const ProductsInput = ({ content, language, property, setEditMenuInfo, updateContent, category, inputState, setInputState, component }) => {

  const [draggableList, setDraggableList] = useState([]);

  const onMoveEnd = (newList, movedItem, oldIndex, newIndex) => {
    const moveItemInArray = (arr, oldIndex, newIndex) => {
      const element = arr[oldIndex];
      arr.splice(oldIndex, 1);
      arr.splice(newIndex, 0, element);
    };

    const newContent = {};
    for (const key of property.keys) {
      newContent[key] = cloneDeep(content[key]);
      moveItemInArray(newContent[key], oldIndex, newIndex);
    }
    
    const newDragglabeList = [...draggableList];
    moveItemInArray(newDragglabeList, oldIndex, newIndex);
    setDraggableList(newDragglabeList);
    
    updateContent(newContent);
  };

  useEffect(() => {
    if (draggableList.length !== content.products.length) {
      setDraggableList(content.products.map((product, idx) => ({ ...product, id: idx + 1 })));
    }
  }, [content, draggableList]);

  return (
    <>
      <div className={`text ${classes.ProductsInputTip}`}><Translation id="templateEditor.rightMenu.productsInput.tip" /></div>
      <DraggableList
        itemKey="id"
        list={draggableList.map((elem, idx) => ({ ...elem, idx }))}
        template={(props) => <ProductInput {...props} content={content} language={language} property={property} setEditMenuInfo={setEditMenuInfo} updateContent={updateContent} setDraggableList={setDraggableList} />}
        onMoveEnd={onMoveEnd}
      />
      {category === "recoproduct" ?
        <AddRecoInput content={content} updateContent={updateContent} property={property} inputState={inputState} setInputState={setInputState} component={component} /> :
        <AddProductInput content={content} updateContent={updateContent} property={property} />
      }
    </>
  );
};

const MonoProductInput = ({ content, language, property, setEditMenuInfo, updateContent, category, inputState, setInputState, component }) => {

  return (
    <>
      <ProductInput 
        content={content} 
        language={language}
        property={property}
        setEditMenuInfo={setEditMenuInfo}
        updateContent={updateContent}
        type="mono"
        itemSelected={0}
      />
      {category === "recoproduct" ?
        <AddRecoInput content={content} updateContent={updateContent} property={property} inputState={inputState} setInputState={setInputState} component={component} /> :
        <AddProductInput content={content} updateContent={updateContent} property={property} />
      }
    </>
  );
};

export const PreheaderVisibilityInput = ({ value, onChange, component }) => {

  const _onChange = (value) => {

    if (!value) {
      const body = component.parent();
      component.move(body, { at: 0 });
    }

    onChange(value);
  };

  return (
    <div className={classes.preheaderVisibilityInput}>
      <Switch
        checked={value}
        onChange={event => _onChange(event.target.checked)}
      />
      <div className="text"><Translation id="preheader.visibility.input" /></div>
    </div>
  );
};

export const RightMenuEditContent = ({
  component,
  block,
  category,
  content,
  updateContent,
  editor,
  onCancel,
  editMenuInfo,
  setEditMenuInfo,
  isPromoCodeOpened,
  scrollableContainerClass,
  templateId,
  language,
  generatedWordings,
  canvasContainerRef,
}) => {

  const [inputState, setInputState] = useState({});

  /* ======== click on element for products / images ======== */
  const onComponentClick = useCallback((_component) => {
    if (block.getCategoryLabel().startsWith("image")) {
      setEditMenuInfo(null);
      return;
    }
    const isMultiProduct = !!block.attributes.attributes.editableProperties?.find(({ type }) => type === "products");
    if (!block.getCategoryLabel().startsWith("product") && !block.getCategoryLabel().startsWith("recoproduct")) {
      return;
    }

    const containers = [];
    component.onAll(child => {
      if (child.attributes.attributes["data-id-product"] || child.attributes.attributes["data-reco-type"]) {
        containers.push(child);
      }
    });

    const idx = containers.findIndex(container => container.contains(_component));
    if (idx >= 0 || !isMultiProduct) {
      const property = block.attributes.attributes.editableProperties.find(({ type }) => type === isMultiProduct ? "products" : "product");
      if (property) {
        setEditMenuInfo({ type: "subproperties", property, idx: isMultiProduct ? idx : null });
        setTimeout(() => {
          const container = document.getElementsByClassName(scrollableContainerClass);
          container[0].scrollTo({
            top: _component.attributes.type === "mj-button" ? container[0].scrollHeight : 0,
            behavior: "smooth",
          });
        }, 300);
      }
    }
  }, [block, setEditMenuInfo, scrollableContainerClass, component]);

  const onBlockClick = useCallback(({ event }) => {
    const elem = event.target.closest("[data-gjs-type*=\"mj-button\"] a, [data-gjs-type*=\"mj-button\"] p, [data-gjs-type*=\"mj-image\"] img");
    if (!elem) {
      setEditMenuInfo(null);
    }
  }, [setEditMenuInfo]);

  useEffect(() => {
    editor.on("buttonClick", onComponentClick);
    editor.on("imageClick", onComponentClick);
    editor.on("kilibaBlockClick", onBlockClick);
    return () => {
      editor.off("buttonClick", onComponentClick);
      editor.off("imageClick", onComponentClick);
      editor.off("kilibaBlockClick", onBlockClick);
    };
  }, [editor, onComponentClick, onBlockClick]);

  /* ================================================ */

  const intl = useIntl();

  const tooltip = useMemo(() => {
    if (["logo", "image", "button"].includes(category) && intl.locale !== language) {
      const type = category;

      return <Translation id={`rightmenu.editContent.tooltip.${type}`} variables={{ lang: intl.messages[`rightmenu.editContent.tooltip.lang.${language}`] }} />;
    }
    return null;
  }, [intl, category, language]);

  if (!block) {
    return;
  }

  const updateContentAtIndex = (key, idx, value) => {
    updateContent({
      [key]: content[key].map((elem, _idx) => idx === _idx ? value : elem),
    });
  };

  if (editMenuInfo?.type === "image") {
    return ( 
      <ImageGaleryMenu
        goBack={() => setEditMenuInfo(null)}
        selectedUrl={editMenuInfo.value[language]}
        onChange={imageUrl => {
          const isMultilingual = typeof editMenuInfo.value !== "string";
          let newValue;

          if (isMultilingual) {
            newValue = getUpdatedMultilingualVariable(intl.locale, language, editMenuInfo.value, imageUrl); 
          } else {
            newValue = imageUrl;
          }

          if (editMenuInfo.index !== null && editMenuInfo.index !== undefined) {
            updateContentAtIndex(editMenuInfo.key, editMenuInfo.index, newValue);
          } else {
            updateContent({ [editMenuInfo.key]: newValue });
          }

          setEditMenuInfo({ type: "image", key: editMenuInfo.key, value: newValue, index: editMenuInfo.index });
        }}
        openCropMenu={() => setEditMenuInfo({ type: "crop", key: editMenuInfo.key, value: content[editMenuInfo.key] })}
        scrollableContainerClass={scrollableContainerClass}
        canvasContainerRef={canvasContainerRef}
        component={component}
        editor={editor}
      />
    );
  }

  if (editMenuInfo?.type === "text") {
    return (
      <div>
        <GeneratedTextMenu
          language={language}
          block={block}
          onChange={(newTexts) => {
            updateContent(newTexts);
            setEditMenuInfo({ type: "text" });
          }}
          selectedWordings={content}
          addSelectedFirst={true}
          goBack={() => setEditMenuInfo(null)}
          isEdit
          generatedWordings={generatedWordings}
        />
      </div>
    );
  }

  if (editMenuInfo?.type === "crop") {
    return (
      <CropMenu
        goBack={() => setEditMenuInfo(null)}
        component={component}
        imageComponentIndex={editMenuInfo.index}
        onChange={imageUrl => {
          const isMultilingual = typeof editMenuInfo.value !== "string";
          let newValue;

          if (isMultilingual) {
            newValue = getUpdatedMultilingualVariable(intl.locale, language, editMenuInfo.value, imageUrl);
          } else {
            newValue = imageUrl;
          }

          if (editMenuInfo.index !== null && editMenuInfo.index !== undefined) {
            updateContentAtIndex(editMenuInfo.key, editMenuInfo.index, newValue);
          } else {
            updateContent({ [editMenuInfo.key]: newValue });
          }

          setEditMenuInfo(null);
        }}
      />
    );
  }

  if (isPromoCodeOpened) {
    return (
      <PromoCodeOptionalMenu
        templateId={templateId}
        updateContent={updateContent}
        content={content}
        editor={editor}
      >
      </PromoCodeOptionalMenu>
    );
  }

  const getGroupTooltip = (group) => {
    if (intl.locale === language) {
      return "";
    }

    if (category === "product" && ["newsletter.rightMenu.propertyGroup.image", "newsletter.rightMenu.propertyGroup.button"].includes(group)) {

      const type = group === "newsletter.rightMenu.propertyGroup.button" ? "button" : "image";

      return (
        <Tooltip title={<Translation id={`rightmenu.editContent.tooltip.${type}`} variables={{ lang: intl.messages[`rightmenu.editContent.tooltip.lang.${language}`] }} />} placement={"right"}>
          <i className="fa-solid fa-circle-info" />
        </Tooltip>
      );
    }
  };

  if (editMenuInfo?.type === "subproperties") {    

    const getSubPropertyValue = (key) => {
      if (typeof editMenuInfo.idx === "number") {
        return content[key][editMenuInfo.idx];
      } else {
        return content[key];
      }
    };

    const updateSubPropertyContent = (key, value) => {
      if (typeof editMenuInfo.idx === "number") {
        updateContentAtIndex(key, editMenuInfo.idx, value);
      } else {
        updateContent({ [key]: value });
      }
    };

    return (
      <div className={classes.container}>
        <div
          className={classes.closeString}
          onClick={() => setEditMenuInfo(null)}
          data-cy={"close-string"}
        >
          <i className="fa-solid fa-angle-left"></i>
          <h4><Translation id="dialog.back" /></h4>
        </div>
        <div className={classes.separator} />
        {editMenuInfo.property.properties.map((property, idx, properties) => (
          <div key={property.key} id={property.key}>
            {!!property.group && (idx === 0 || properties[idx - 1].group !== property.group) &&
              <div>
                {idx !== 0 && <div className={classes.separator} />}
                <h3 className={classes.groupName}>
                  <Translation id={property.group} />
                  {getGroupTooltip(property.group)}
                </h3>
              </div>
            }

            <div className={classes.property}>
              <div className={`textMedium ${classes.propertyLabel}`} >{property.label && <Translation id={property.label} />}</div>
              {property.type === "link" && <LinkPropertyInput value={getSubPropertyValue(property.key)} onChange={(value) => updateSubPropertyContent(property.key, value)} />}
              {property.type === "string" && <StringPropertyInput value={getSubPropertyValue(property.key)} onChange={(value) => updateSubPropertyContent(property.key, value)} />}
              {property.type === "color" && <ColorPropertyInput value={getSubPropertyValue(property.key)} onChange={(value) => updateSubPropertyContent(property.key, value)} />}
              {property.type === "price" && <PricePropertyInput value={getSubPropertyValue(property.key)} onChange={(value) => updateSubPropertyContent(property.key, value)} />}
              {property.type === "image" && <ImagePropertyInput value={getSubPropertyValue(property.key)} onChange={(value) => updateSubPropertyContent(property.key, value)} openImageMenu={() => setEditMenuInfo({ type: "image", key: property.key, index: editMenuInfo.idx, value: getSubPropertyValue(property.key) })} editor={editor} openCropMenu={() => setEditMenuInfo({ type: "crop", key: property.key, value: getSubPropertyValue(property.key), index: editMenuInfo.idx })} />}
            </div>
          </div>
        ))}
      </div>
    );
  };

  const getTitle = () => {
    if (category === "columnComponent") {
      if (component.attributes.attributes["data-blocklabel"].startsWith("columnSeparator")) {
        return "blockManager.category.columnComponent.columnSeparator";
      }

      return `blockManager.category.columnComponent.${component.attributes.attributes["data-blocklabel"]}`;
    }

    return `blockManager.category.${category}`;
  };

  return (
    <div className={classes.container}>
      <div className={classes.elementButton} onClick={() => onCancel()}>
        <div className="textMedium"><Translation id="newsletter.rightMenu.element" /></div>
        <i className="fa-solid fa-circle-plus" />
      </div>
      <h2 className={classes.title}>
        <Translation id={getTitle()} />
        {tooltip &&
          <Tooltip title={tooltip} placement={"right"}>
            <i className="fa-solid fa-circle-info" />
          </Tooltip>
        }
      </h2>
      <div className={classes.separator} />
      {block.attributes.attributes.editableProperties.map((property, idx, properties) => (
        <div key={property.key}>
          {!!property.group && (idx === 0 || properties[idx - 1].group !== property.group) &&
            <div>
              {idx !== 0 && <div className={classes.separator} />}
              <h3 className={classes.groupName}>
                <Translation id={property.group} />
                {getGroupTooltip(property.group)}
              </h3>
            </div>
          }

          <div className={classes.property}>
            <div className={`textMedium ${classes.propertyLabel}`} >{property.label && <Translation id={property.label} />}</div>
            {property.type === "image" && <ImagePropertyInput value={content[property.key]} onChange={(value) => updateContent({ [property.key]: value })} openImageMenu={() => setEditMenuInfo({ type: "image", key: property.key, value: content[property.key] })} editor={editor} openCropMenu={() => setEditMenuInfo({ type: "crop", key: property.key, value: content[property.key] })} />}
            {property.type === "link" && <LinkPropertyInput value={content[property.key]} onChange={(value) => updateContent({ [property.key]: value })} />}
            {property.type === "string" && <StringPropertyInput value={content[property.key]} onChange={(value) => updateContent({ [property.key]: value })} />}
            {property.type === "logo" && <ImagePropertyInput2 value={content[property.key]} onChange={(value) => updateContent({ [property.key]: value })} editor={editor} component={component} type="logos" templateId={templateId} openCropMenu={() => setEditMenuInfo({ type: "crop", key: property.key, value: content[property.key] })} />}
            {property.type === "color" && <ColorPropertyInput value={content[property.key]} onChange={(value) => updateContent({ [property.key]: value })} />}
            {property.type === "generatedTexts" && <GeneratedTextsInput content={content} language={language} block={block} openTextMenu={() => setEditMenuInfo({ type: "text" })} />}
            {property.type === "promoCode" && <PromoCodeOptionalMenu templateId={templateId} updateContent={updateContent} content={content} editor={editor}></PromoCodeOptionalMenu>}
            {property.type === "products" && <ProductsInput content={content} language={language} property={property} setEditMenuInfo={setEditMenuInfo} updateContent={updateContent} category={category} inputState={inputState} setInputState={setInputState} component={component} />}
            {property.type === "preheaderVisibility" && <PreheaderVisibilityInput value={content[property.key]} onChange={(value) => updateContent({ [property.key]: value })} editor={editor} component={component} />}
            {property.type === "price" && <PricePropertyInput value={content[property.key]} onChange={(value) => updateContent({ [property.key]: value })} />}
            {property.type === "imageSize" && <ImageSizeInput content={content} updateContent={updateContent} component={component} editor={editor} options={property.options} ></ImageSizeInput>}
            {property.type === "imageAlign" && <ImageAlignInput value={content[property.key]} onChange={(value) => updateContent({ [property.key]: value })} />}
            {property.type === "product" && <MonoProductInput content={content} language={language} property={property} setEditMenuInfo={setEditMenuInfo} updateContent={updateContent} category={category} inputState={inputState} setInputState={setInputState} component={component} />}
            {property.type === "columns" && <ColumnsInput content={content} updateContent={updateContent} editor={editor} component={component} />}
          </div>
        </div>
      ))}
    </div>
  );
};