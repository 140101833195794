import classes from "./BtnRadiusInput.module.scss";
import { Translation } from "../.././../common/Translation";


export const BtnRadiusInput = ({  onChange , setBtnRadiusType, btnRadiusType }) => {


  const handleClick = (key, value) => {
    setBtnRadiusType(key);  
    onChange(value);
  };

  return (
    <div className={classes.btnRadiusInput}>
      <div key="hard" onClick={() => handleClick("hard", 0)} className={`${classes.btnRadiusBox} ${btnRadiusType === "hard" ? classes.btnRadiusBoxSelected : ""}`}>
        <span className={classes.btnHard}></span>
        <Translation id="field.buttonRadius.hard" />
      </div>

      <div key="rounded" onClick={() => handleClick("rounded", 10)} className={`${classes.btnRadiusBox} ${btnRadiusType === "rounded" ? classes.btnRadiusBoxSelected : ""}`}>
        <span className={classes.btnSoft}></span>
        <Translation id="field.buttonRadius.soft" />
      </div>

      <div key="custom" onClick={() => handleClick("custom")} className={`${classes.btnRadiusBox} ${btnRadiusType === "custom" ? classes.btnRadiusBoxSelected : ""}`} >
        <span className={classes.btnCustom}></span>
        <Translation id="field.buttonRadius.custom" />
      </div>
    </div>
  );
};
